import axios from "axios"

const HOST_URL = 'https://paolashopping.com'

// LOGIN USER
export function loginUser(email, password) {
    const params = { email: email, password: password }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis/login`
    })
}

// SOCIAL LOGIN
export function socialLogin(socialId) {
    const params = { social_id: socialId }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis/sociallogin`
    })
}

// REGISTER USER
export function registerUser(name, email, password, country, mobileNo, address, socialId) {
    const params = { name: name, email: email, password: password, country: country, mobile_number: mobileNo, user_type: "seller", social_id: socialId, description: "", address: address, latitude: 32.1113, longitude: 44.3232 }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis//user/create`
    })
}

// FORGOT PASSWORD
export function forgotPassword(email) {
    const params = { email: email }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis/user/forgotpassword`
    })
}

// UPDATE PHONE NUMBER
export function updatePhoneNo(token, number) {
    const params = { token: token, mobile_number: number }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis/user/updatephoneno`
    })
}

// CHANGE PASSWORD
export function changePassword(oldpassword, newpassword, email) {
    const params = {
        password: oldpassword,
        new_password: newpassword,
        email: email
    }
    return axios.request({
        method: 'post',
        params: params,
        url: `${HOST_URL}/apis/user/changePassword`
    })
}

// PRODUCT REVIEWS
export function productReviews(id) {
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/getReviews/${id}`
    })
}

// GET SELLER ADDRESSES
export function getSellerAddresses(id) {
    const params = { id: id }
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/product/selleraddresses`
    })
}


// EMAIL FEEDBACK
export function emailFeedback(name, email, message) {
    const params = { name: name, email: email, message: message }
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/user/feedback`
    })
}


// ************* Seller Apis ************ //
// MY ORDERS
export function requestMyOrders(id) {
    return postApiCall({ seller_id: id }, '/apis/order/myorders')
}

// UPLOAD PRODUCT
export function requestUploadProduct(token, title, price, discount, categoryId, stockUnit, description, sellerId, files, deliveryCharge) {
    let data = new FormData()
    data.append('token', token)
    data.append('product_name', title)
    data.append('price', price)
    data.append('discount', discount)
    data.append('category_id', categoryId)
    data.append('stock_unit', stockUnit)
    data.append('description', description)
    data.append('seller_id', sellerId)

    for (let i = 0; i < files.length; i++) {
        data.append('file[]', files[i])
    }
    data.append('delivery_charges', deliveryCharge)

    return axios.post(`${HOST_URL}/apis/products/create`, data)
}

// UPDATE PRODUCT
export function requestUpdateProduct(token, title, price, discount, categoryId, stockUnit, description, productId, files, deliveryCharge) {
    let data = new FormData()
    data.append('token', token)
    data.append('product_id', productId)
    data.append('product_name', title)
    data.append('price', price)
    data.append('discount', discount)
    data.append('category_id', categoryId)
    data.append('stock_unit', stockUnit)
    data.append('description', description)

    for (let i = 0; i < files.length; i++) {
        data.append(`images[${i}]`, files[i])
    }
    data.append('delivery_charges', deliveryCharge)

    return axios.post(`${HOST_URL}/apis/products/update`, data)
}

// DELETE PRODUCT
export function requestDeleteProduct(token, id) {
    return postApiCall({ token: token, id: id }, '/apis/products/delete')
}

// TOP SELLING PRODUCTS
export function requestTopSellingProducts(id) {
    return postApiCall({ seller_id: id }, '/apis/products/topselling')
}

// MARK DELIVERED
export function updateOrderStatusEarnings(orderId, sellerId) {
    return postApiCall({ order_id: orderId, user_id: sellerId }, '/apis/order/updateorderstatusearning')
}

// RECENT TRANSACTIONS
export function requestRecentTransactions(id) {
    return postApiCall({ seller_id: id }, '/apis/order/recent')
}

// ORDERS INSIGHT
export function requestOrdersInsight(id) {
    return postApiCall({ seller_id: id }, '/apis/order/insight')
}

// MORE BY SELLER
export function requestMoreBySeller(id) {
    return getApiCall(`/apis/products/moreBySeller/${id}`)
}

// DELIVERY BOYS LIST
export function requestDeliveryBoysList(sellerId) {
    return postApiCall({ seller_id: sellerId }, '/apis/user/getDeliveryBoys')
}

// SELLER MORE ADDRESSES
export function sellerMoreAddresses(sellerId) {
    return postApiCall({ user_id: sellerId }, '/apis/user/sellermoredaddresses')
}

// ADD NEW SELLER ADDRESS
export function addNewSellerAddress(token, address) {
    return postApiCall({
        login_token: token,
        address: address,
        lat: 51.213890,
        long: -102.462776
    }, '/apis/user/newselleraddress')
}

// UPDATE ADDRESS
export function updateAddress(token, address, addressId) {
    return postApiCall({
        login_token: token,
        id: addressId,
        address: address,
        lat: 51.213890,
        long: -102.462776
    }, '/apis/user/updatemoreaddress')
}

// REQUESTS TO PICK ORDERS
export function requestsToPickOrders(sellerId) {
    return postApiCall({ seller_id: sellerId }, '/apis/orderpickrequest/request')
}

// DELETE PICK REQUEST
export function deletePickRequest(requestId) {
    return postApiCall({ id: requestId }, '/apis/orderpickrequest/deleterequest')
}

// SUB CATEGORIES AGAINST CATEGORY
export function requestCategoriesAgainstCategory(categoryId) {
    return getApiCall(`/apis/categories/getAllSubCategories/${categoryId}`)
}

// ALL CATEGORIES SUBCATEGORIES
export function requestAllCategoriesSubcategories() {
    return getApiCall('/apis/categories/getAllCategoriesSucategories')
}

// ALL CATEGORIES
export function requestAllCategories() {
    return getApiCall('/apis/categories/getAllCategories')
}

// DELETE PRODUCT IMAGE
export function requestDeleteImage(id) {
    return postApiCall({ image_id: id }, '/apis/products/deleteProductImage')
}

// SEND PICK REQUEST
export function sendPickRequest(sellerId, orderId, dboyId) {
    return postApiCall(
        {
            seller_id: sellerId, order_id: orderId, delivery_boy_id: dboyId,
            pick_request_status: 'pending', deliver_status: 'pending', description: ''
        },
        '/apis/orderpickrequest/create')
}

// DELETE PICK REQUEST
export function requestDeletePick(requestId) {
    return postApiCall({ id: requestId }, '/apis/orderpickrequest/deleterequest')
}

// CHECK REQUESTS AGAINST SELLER
export function requestsCheckAgainstSeller(sellerId) {
    return postApiCall({ seller_id: sellerId }, '/apis/orderpickrequest/request')
}



// ----------- POST API CALL ------------- //
function postApiCall(params, url) {
    // const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    //    }
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}${url}`
    })
}

function getApiCall(url) {
    return axios.request({
        method: "get",
        url: `${HOST_URL}${url}`
    })
}