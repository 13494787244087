import './App.css';

import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import HomePage from './pages/home_page';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { Route, Switch } from 'react-router-dom';
import ProductInfo from './pages/product_info';
import CategoryProducts from './pages/category_products';
import LoginSignUp from './pages/login_sign_up';
import MyAccount from './pages/my_account';
import MyOrders from './pages/my_orders';
import CartPage from './pages/cart_page';
import WishtList from './pages/wishlist_page';
import PaymentMode from './pages/payment_mode';
import PaymentSuccess from './pages/payment_success';
import PaymentFailed from './pages/payment_failed';
import CreditCardForm from './components/credit_card_form';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contactus';
import SearchPage from './pages/search_page';
import TermsConditions from './pages/terms_conditions';
import PrivacyPolicy from './pages/privacy_policy';
import {useDispatch} from 'react-redux'
import { getCategories } from './redux/ducks/category_reducer';
import SellerApp from '../src/seller/src/App.js'
import WriteReview from './pages/write_review';

function App(props) {
  console.log('App js is called.........')
  const dispatch = useDispatch()
  dispatch(getCategories())

  const userType = props.userType

  if (userType === undefined){
    return (<div>
      Select user type first.
    </div>)
  }

  return (
    <div className="App">
      {
        userType === 'customer' ? customerRoutes() : sellerRoutes()
      }      
    </div>
  )

  function customerRoutes(){
    return (
      <div>
        <Header/>
         <Switch>
          <Route exact path='/'><HomePage/></Route>
          <Route exact path='/termsconditions'><TermsConditions/></Route>
          <Route exact path='/privacypolicy'><PrivacyPolicy/></Route>
          <Route exact path='/product/:id' component={ProductInfo}/>
          <Route exact path='/search/:keyword' component={SearchPage}/>
          <Route exact path='/login'><LoginSignUp/></Route>
          <Route exact path='/about'><AboutUs/></Route>
          <Route exact path='/contact'><ContactUs/></Route>
          <Route exact path='/myaccount'><MyAccount/></Route>
          <Route exact path='/myorders'><MyOrders/></Route>
          <Route exact path='/cart'><CartPage/></Route>
          <Route exact path='/review'><WriteReview/></Route>
          <Route exact path='/wishlist'><WishtList/></Route>
          <Route exact path='/paymentmode/:amount/:ids' component={PaymentMode}/>
          <Route exact path='/paymentsuccesspage'><PaymentSuccess/></Route>
          <Route exact path='/paymentfailedpage'><PaymentFailed/></Route>
          <Route exact path='/paywithstripe/:amount/:ids' component={CreditCardForm}/>
          <Route exact path='/category/products/:id' component={CategoryProducts}/>
        </Switch>
        <Footer/>
      </div>
    )
  }

  function sellerRoutes(){
    return (
      <div>
        <SellerApp/>
      </div>
    )
  }

}

export default App;
