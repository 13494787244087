import React, {useState, useEffect} from 'react'
import { Loader } from '../components/loader'
import { PRODUCT_HOST } from '../constants'

import { Row, Col } from 'react-bootstrap'
import {IoCheckmarkDone} from 'react-icons/io5'
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { deletePickRequest, requestMyOrders, requestsToPickOrders, updateOrderStatusEarnings } from '../redux/sagas/requests/api_requests'
import {BsPerson} from 'react-icons/bs'
import {GoLocation} from 'react-icons/go'
import {MdPhone} from 'react-icons/md'
import { paymentRow, productRow } from '../utils'
import { selectdLocale } from '../langs/locale'

export default function MyOrders() {
    const [orders, setOrders] = useState(undefined)
    const [userData, setUserData] = useState(useSelector(state => state.user.user))
    const [user, setUser] = useState(undefined)
    const [requests, setRequests] = useState(undefined)
    const [startLoader, setStartLoader] = useState(false)

    let history = useHistory()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    // Seller 
    useEffect(() => {
        if (userData !== undefined){
            console.log(userData)
            if (userData.user !== undefined){
                setUser(userData.user)
            } else {
                setUser(userData.data)
            }
        } else {
            history.push('/login')
        } 
    }, [userData])

    // Requests Sent
    useEffect(async () => {
       if (user !== undefined){
            const response = await requestsToPickOrders(user.id)
            console.log(response.data)
            if (response.data.status === 'success'){
                setRequests(response.data.data)
            }
       }
    }, [user])

    useEffect(async() => {
        if (user !== undefined){
            const response = await requestMyOrders(user.id)
            setOrders(response.data.data)
        }
    }, [user])

    if (startLoader){
        return Loader()
    }

    if (requests === undefined){
        return Loader()
    }

    if (orders === undefined){
        return Loader()
    }

    if (orders.length === 0){
        return (<div style={{minHeight:'77vh', paddingTop:'100px'}}>
            <h4>You have 0 orders.</h4>
        </div>)
    }

    const w = window.innerWidth

    return (
        <div className="main-content" style={{minHeight:'77vh'}}>
            {orders.map((o, i)=>{
                const orderStatus = o.order_status;
                const requestArray = requests.filter(req => o.id === req.order_id)
                let request = undefined
                if (requestArray.length > 0){
                    request = requestArray[0]
                }
                return (
                    <div key={i} className="order-card" style={{width:w > 500 ? '60%': '90%'}}>
                        <div  style={{backgroundColor:'white',borderRadius:'5px 5px 0px 0px', borderBottom:'1px solid #ccc'}}>
                        <Row>
                            <Col md="8">
                                <div style={{padding:'10px', textAlign:'left', fontWeight:'bold'}}>Order ID: <span style={{color:'orange', fontWeight:'bold'}}>{o.id}</span> </div>
                            </Col>
                            <Col md="4">
                                <div style={{padding:'10px', textAlign:'right'}}>Ordered On: {dateFormat(o.created_at, "mmmm dS, yyyy")}</div>
                            </Col>
                        </Row>
                        </div>
                        {o.orderdetail.map((od, odi)=>{
                            const q = od.quantity
                            const r = od.is_reviewed
                            return (
                                <div key={odi}>
                                    {od.product_detail.map((p, pi)=>(
                                        <>
                                            {productRow(p, q)}
                                        </>
                                    ))}
                                </div>
                            );
                        })}
                        
                        {paymentRow(o.total_amount.toFixed(2), orderStatus, lang)}
                        {orderStatus !== 'delivered' ? 
                            <>
                                {o.address !== '' ? shippingLocation(o) : ''}
                                {(request === undefined && o.address !== '') ? orderPickButton(history, o.id, lang): ''}
                                {request !== undefined ? <div>
                                    {requestSent(request.delivery_boy_id, request.id, o.id)}
                                </div>: ''}
                                {(o.notes !== '')?pickupLocation(o.notes, o.id, user.id):''}
                            </>
                        : ''}

                        
                    </div>
                )
                
            })}
        </div>
    )

    function pickupLocation(address, orderId, sellerId){
        return (
            <div style={{padding:'10px'}}>
            <h4>Pickup Location:</h4>
            <div>{address}</div>
            <br/>
            <button 
            onClick={async ()=>{
                setStartLoader(true)
                const response = await updateOrderStatusEarnings(orderId, sellerId)
                setStartLoader(false)
                if (response.data.status === 'success'){
                    setOrders(undefined)
                    const response = await requestMyOrders(user.id)
                    setOrders(response.data.data)
                }
            }}
            className="button" style={{width:'220px'}}>Mark as Delivered</button>
            </div>
        )
    }

    function requestSent(dboy, requestId, id){
        return (
            <div style={{marginLeft:'10px', marginRight:'10px'}}>
                <br/>
                <h4 style={{color:'black', fontWeight:'bold'}}>{lang.requestSentToLabel}:</h4>
                <hr/>
                <div style={{marginTop:'10px',marginBottom:'8px', fontSize:'18px'}}>
                    <span style={{paddingRight:'5px'}}><BsPerson/></span><span>{dboy.full_name}</span> 
                    <span style={{paddingRight:'5px', paddingLeft:'50px'}}><MdPhone/></span><span>{dboy.mobile_number}</span>
                </div>
                <div style={{marginBottom:'5px', paddingLeft: '5%', paddingRight:'5%'}}>
                    <span style={{paddingRight:'5px'}}><GoLocation/></span>
                    <span>{dboy.address}</span>
                </div>
                <br/>
                <div style={{marginBottom:'5px'}}>
                    <button 
                        onClick={async ()=>{
                            const response = await deletePickRequest(requestId)
                            if (response.data.status === 'success'){
                                history.push(`/orderpickrequest/${id}`)
                            } else {
                                alert('Error occured while deleting request!')
                            }
                        }}
                        className="button" style={{width:'250px'}}>
                        <span>{lang.makeRequestToOtherLabel}</span> 
                    </button>
                </div>
                <br/>
                
            </div>
        )
    }
}

function shippingLocation(order){
    const id = order.id
    console.log(order.address)
    return (
        <div style={{padding:'10px'}}>
        <h4>Shipping Detail:</h4>
        <hr/>
        <div style={{marginTop:'10px',marginBottom:'8px', fontSize:'18px'}}><span style={{paddingRight:'5px'}}><BsPerson/></span><span>{order.userdetail.full_name}</span> 
        <span style={{paddingRight:'5px', paddingLeft:'50px'}}><MdPhone/></span><span>{order.userdetail.mobile_number}</span>
        </div>
        
        <div style={{marginBottom:'5px'}}><span style={{paddingRight:'5px'}}><GoLocation/></span><span>{order.address}</span></div>
        </div>
    )
}

function orderPickButton(history, id, lang){
    return (
        <div style={{marginBottom:'5px'}}>
                <button 
                onClick={()=>history.push(`/orderpickrequest/${id}`)}
                className="button" style={{width:'250px'}}>
                    <span>{lang.orderPickRequestLabel}</span> 
                </button>
        </div>
    )
}



