import React, {useState, useEffect} from 'react'
import { selectdLocale } from '../../langs/locale'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { setCountry, setUser, setUserType } from '../../redux/ducks/user_reducer'

export default function Footer() {
    const [ value, setValue ] = useState(null)
    const dispatch = useDispatch()

    const [selectedUser, setSelectedUser] = useState(useSelector(state => state.user.userType))

    const country = useSelector(state => state.user.country)
    useEffect(() => {
        if (country !== undefined){
            setValue(country)
        }
    }, [country])

    const user = useSelector(state => state.user.user)
    let lang = selectdLocale(useSelector(state => state.user.locale))
    let history = useHistory()

    return (
        <footer className="footer">
            <div className="footer-bottom" style={{backgroundColor:'#000000', paddingTop:'10px', paddingBottom:'10px'}}>

            <div style={{marginBottom:'10px'}}>
                    <span onClick={()=>history.push('/privacypolicy')} className="footer-btn">
                        {lang.privacyPolicyLabel}
                    </span>
                    <span onClick={()=>history.push('/termsconditions')} className="footer-btn">
                        {lang.termsConditionsLabel}
                    </span>
                    {user !== undefined ? 
                         <span onClick={()=>history.push('/contact')} className="footer-btn">
                         {lang.contactUsLabel}
                        </span>
                    :''}
                    {country !== undefined ?
                        <>
                            <select 
                                value={country}
                                onChange={(e)=>{
                                    dispatch(setCountry(e.target.value))
                                }}
                                style={{paddingLeft:'5px', paddingRight:'5px', borderRadius:'5px'}}>
                                <option value="Canada">Canada</option>
                                <option value="Ecuador">Ecuador</option>
                            </select>
                        </>
                        :''
                    }

                    {selectedUser !== undefined ?
                        <>
                            <select 
                                value={selectedUser}
                                onChange={(e)=>{
                                    dispatch(setUser(undefined))
                                    dispatch(setUserType(e.target.value))
                                    setTimeout(() => {
                                        window.location.replace('/')
                                      }, 500)
                                    
                                }}
                                style={{paddingLeft:'5px', marginLeft:'5px', paddingRight:'5px', borderRadius:'5px'}}>
                                <option value="customer">Customer</option>
                                <option value="seller">Seller</option>
                            </select>
                        </>
                        :''
                    }   
                    
                   
            </div>
                    <p className="footer-copyright" style={{fontSize:'18px', color:'#ccc'}}>Copyright © { ( new Date() ).getFullYear() } Panda Store. All Rights Reserved.</p>

                    <div style={{marginTop:'-7px'}}>
                        <img src="/assets/images/user_logo.jpg" width="60" height="60"/>
                    </div>
            </div>
        </footer>
    )
}
