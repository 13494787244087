export const en = {
    homelabel: 'Home',
    categoriesLabel:'Categories',
    aboutustext: 'About Us',
    myOrdersLabel: 'My Orders',
    myProfileLabel: 'My Profile',
    englishLabel: 'English',
    spanishLabel: 'Spanish',
    loginLabel: 'Login',
    logoutLabel: 'Logout',
    topRatedProducts: 'Top Rated Products',
    newlyLaunchedProducts: 'Newly Launched Products',
    discountedProducts: 'Discounted Products',
    aboutUs: 'About Us',
    contactUsLabel: 'Contact Us',
    languageLabel: 'Language',
    categoriesLabel: 'Categories',
    searchLabel: 'Search',
    totalAmountToPay: 'Total amount to pay:',
    accountText: 'Account',
    offersLabel: 'Offers',
    cardHolderNameLabel: 'Cardholder name',
    cardNumberLabel: 'Card number',
    expirationDateLabel: 'Expiration date',
    securityCodeLabel: 'Security code',
    privacyPolicyLabel: 'Privacty Policy',
    termsConditionsLabel: 'Terms and Conditions',
    zeroItemsInCartLabel: 'You have 0 items in cart.',
    priceLabel: 'Price',
    deliveryFeeLabel: 'Delivery fee',
    selectPickupLocationLabel: 'Select Pickup Location',
    checkoutLabel: 'Checkout',
    pickupNotAvailable: 'Pick up not available for different sellers.',
    noProductsAvailabelLabel: 'No product available.',
    selectCountryLabel: 'Select Country',
    feedbackQueryTextLabel: 'Let us know your feedback, queries or issue regarding app or features.',
    fullNameLabel: 'Full name',
    enterFullNameLabel: 'Enter full name',
    phoneNumberLabel: 'Phone number',
    enterPhoneNumberLabel: 'Enter phone number',
    yourFeedbackLabel: 'Your Feedback',
    enterYourFeedbackLabel: 'Enter your feedback',
    feedbackSentSuccessLabel: 'Feedback sent successfully',
    failedToSendFeedbackLabel: 'Failed to send feedback!',
    submitLabel: 'Submit',
    registerFirstTimeLabel: 'Fill some other info in register form at first time.',
    emailOrPasswordWrongLabel: 'Email or password is wrong!',
    mustBeSixCharactersLabel:'Password must be at least 6 characters',
    invalidEmailLabel: 'Invalid email',
    bothFieldsRequiredLabel:'Both fields are required!',
    emailExistsLabel: 'Email already exists!',
    allFieldsRequiredLabel: 'All fields are required!',
    emailAddressLabel: 'Email address',
    enterEmailLabel: 'Enter email',
    willNeverShareEmailLabel: 'We will never share your email with anyone else.' ,
    passwordLabel: 'Password',
    forgotPasswordLabel: 'Forgot Password',
    orContinueWithLabel: 'Or continue with:',
    signInWithGoogle: 'Sign in with Google',
    registerLabel: 'Register',
    notRequiredIfSocialLabel: 'Password (not required if social registration)',
    addressLabel: 'Address',
    enterAddressLabel: 'Enter address',
    countryLabel: 'Country',
    phoneNoLabel: 'Phone Number',
    enterPhoneNumberLabel: 'Enter phone number',
    signUpLabel: 'Sign Up',
    loginWithSellerAccount: 'Login with seller account only',
    someErrorOccuredLabel: 'Some error ocured!',
    ratingRequiredLabel: 'Rating is required!',
    howWasExperienceLabel: 'How was your experience?',
    writeYourFeedbackLabel: 'Write your feedback',
    giveFeedbackLabel: 'Give Feedback',
    noProductAddedFavoriteLabel: 'No product added to favorites.',
    descriptionLabel: 'Description',
    addToCartLabel: 'Add to Cart',
    addToWishListLabel: 'Add to Wishlist',
    moreBySellerLabel: 'More by seller', 
    ratingsReviewsLabel: 'Ratings & Reviews', 
    avgRatingLabel: 'Avg. Ratings',
    totalLabel: 'Total',
    shareToLabel: 'Share to:',
    orderPlacedSuccessLabel: 'Your order is placed successfully',
    goToLabel: 'Go to',
    pageLabel: 'page',
    payWithPaypalLabel: 'Pay with Paypal',
    payWithStripeLabel: 'Pay with Stripe',
    failedPaymentLabel: 'Your order could not be placed due to failed payment!',
    have0oredersLabel: 'You have 0 orders.',
    orderIdLabel: 'Order ID',
    orderedOnLabel: 'Ordered On',
    quantityLabel: 'Quantity',
    writeReviewLabel: 'Write review',
    orderStatusLabel: 'Order status',
    paymentLabel: 'Payment',
    pickupLocationLabel: 'You can pick order at',
    addAddressLabel: 'Add address',
    hideLabel: 'Hide',
    addLabel: 'Add',
    resetPasswordLabel: 'Reset Password',
    oldPasswordLabel: 'Old Password',
    newPasswordLabel: 'New Password',
    passwordUpdatedSuccessLabel: 'Password updated successfully',
    failedToUpdatePasswordLabel: 'Failed to update!',
    changePasswordLabel: 'Change Password',
    otherAddressesLabel: 'Other Addresses'
}