import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const GET_PRODUCTS = 'getproducts'
const SET_PRODUCTS = 'setproducts'

export const GET_HOME_PRODUCTS = 'gethomeproducts'
const SET_HOME_PRODUCTS = 'sethomeproducts'

export const GET_SELLER_PRODUCTS = 'getsellerproducts'
const SET_SELLER_PRODUCTS = 'setsellerproducts'

const GET_PRODUCT_TO_OPEN = 'getproductoopen'
const SET_PRODUCT_TO_OPEN = 'setproducttoopen'

export const getProducts = (categoryId, country, pageNo)=>({
    type: GET_PRODUCTS,
    categoryId,
    country,
    pageNo
})

export const setProducts = (products)=>({
    type: SET_PRODUCTS,
    products
})

export const getHomeProducts = (country)=>({
    type: GET_HOME_PRODUCTS,
    country
})

export const setHomeProducts = (homeProducts)=>({
    type: SET_HOME_PRODUCTS,
    homeProducts
})

export const getSellerProducts = (sellerId) => ({
    type: GET_SELLER_PRODUCTS,
    sellerId
})

export const setSellerProducts = (moreProducts) => ({
    type: SET_SELLER_PRODUCTS,
    moreProducts
})

export const getProductToOpen = ()=>({
    type: GET_PRODUCT_TO_OPEN
})

export const setProdcutToOpen = (prod) =>({
    type: SET_PRODUCT_TO_OPEN,
    prod
})

const initialState = {
    products: undefined,
    homeProducts: undefined,
    moreProducts: undefined,
    productToOpen: undefined
}

const productReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_PRODUCTS:
            const {products} = action;
            return {...state, products}  
        case SET_HOME_PRODUCTS:
            const {homeProducts} = action;
            return {...state, homeProducts}  
        case SET_SELLER_PRODUCTS:
            const {moreProducts} = action;
            return {...state, moreProducts}  
        case GET_PRODUCT_TO_OPEN:
            return state.productToOpen;
        case SET_PRODUCT_TO_OPEN:
            const {prod} = action;
            return {...state, productToOpen: prod}               
        default:
            return state;    
    }
}

const persistConfig = {
    keyPrefix: "panda-",
    key: "products",
    storage
}

export default persistReducer( persistConfig, productReducer );