import { combineReducers, createStore, applyMiddleware } from "redux";
import productsReducer from "./ducks/products_reducer";
import createSagaMiddleware from "@redux-saga/core";
import {persistStore} from 'redux-persist'
import { watcherSaga } from "./sagas/root_sagas";
import category_reducer from "./ducks/category_reducer";
import cart_reducer from "./ducks/cart_reducer";
import user_reducer from "./ducks/user_reducer";

const reducer = combineReducers({
    product: productsReducer,
    category: category_reducer,
    cartItem: cart_reducer,
    user: user_reducer
})


const sagaMiddleWare = createSagaMiddleware();

const middleware = [sagaMiddleWare];

const store = createStore(reducer, {}, applyMiddleware(...middleware));

sagaMiddleWare.run(watcherSaga);

export const persistor = persistStore( store );

export default store;