import axios from "axios"

const HOST_URL = 'https://paolashopping.com'

// HOME PRODUCTS
export function requestHomeProducts(country){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/getAllProducts?country=${country}`
    })
}

// PRODUCTS AGAINST CATEGORY
export function requestProductsByCategory(action){
    return axios.request({
        method: "post",
        url: `${HOST_URL}/apis/products/getProducts?category_id=${action.categoryId}&page=${action.pageNo}&country=${action.country}`
    })
}

// PRODUCTS MORE BY SELLER
export function requestProductsBySeller(sellerId){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/moreBySeller/${sellerId}`
    })
}

// CATEGORIES
export function requestCategories(){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/categories/getAllCategories`
    })
}

// SUB CATEGORIES AGAINST CATEGORY
export function requestCategoriesAgainstCategory(categoryId){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/categories/getAllSubCategories/${categoryId}`
    })
}

// BANNERS
export function requestBanners(){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/getAllBanners?web=1`
    })
}

