import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from '../components/loader';
import { Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import { PRODUCT_HOST } from '../constants'
import { CgAdd } from 'react-icons/cg';
import {BiMinusCircle} from 'react-icons/bi'
import {TiDeleteOutline} from 'react-icons/ti'
import { changeQuantity, removeCartItem } from '../redux/ducks/cart_reducer';
import { getSellerAddresses, placeOrders } from '../redux/sagas/requests/api_requests';
import { discountedPrice, subTotalAmount, totalDeliveryFee, changeItemQuantity } from '../utils';
import OrderToPlace from '../Models/order_to_place';
import { useHistory } from 'react-router';
import amountToPay from '../helper/cart_helper';
import { selectdLocale } from '../langs/locale';
import { languages } from 'country-data';

export default function CartPage() {
    const [key, setKey] = useState('delivery');
    const [sellerAddresses, setSellerAddresses] = useState(undefined)

    const cartItems = useSelector(state => state.cartItem.cartItems)
    const user = useSelector(state => state.user.user)
    let history = useHistory()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    const addressess = useSelector(state => state.user.addressess)

    useEffect(async () => {
        if (cartItems !== undefined && cartItems.length > 0){
            let resp = await getSellerAddresses(cartItems[0].product.id)
            console.log(resp.data.data)
            setSellerAddresses(resp.data.data)
        }
    }, [cartItems])

    
    const dispatch = useDispatch()

    if (user === undefined){
        return (
            <div style={{minHeight:'77vh', paddingTop:'100px'}}>
                <h4>Please <span onClick={()=> history.push('/login')} style={{color:'orange', cursor:'pointer'}}>{lang.loginLabel}</span> before continue.</h4>
            </div>
        )
    }

    if (cartItems === undefined){
        return Loader()
    }

    if (cartItems.length === 0){
        return (
            <div style={{minHeight:'77vh', paddingTop:'100px'}}><h4>{lang.zeroItemsInCartLabel}</h4></div>
        )
    }

    return (
        <div style={{minHeight:'77vh'}}>
        <Row>
            <Col md="7">
            {cartItems.map((item, index)=>(
                <>
                    <Row key={index}>
                        <Col md="12">
                            <div>
                                {productRow(item, dispatch, lang)}
                            </div>
                        </Col>
                    </Row>
                </>
            ))}
            </Col>
            <Col md="5">
                {cartItems.length > 0 ? 
                    <div style={{minWidth:'30%', marginTop:'30px', marginLeft:'20px'}}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        >
                        <Tab eventKey="pick" title="Pick Yourself">
                            {pickYourself(cartItems, sellerAddresses, user, history, lang)}
                        </Tab>
                        <Tab eventKey="delivery" title="Need Delivery">
                            {needDelivery(cartItems, addressess, user, history, lang)}
                        </Tab>
                        
                        </Tabs>
                    </div>
                :''}
                
           </Col>
        </Row>
        </div>
    )
}

function productRow(cartItem, dispatch, lang){
    let product = cartItem.product
    return (
        <>
            <Row className="align-items-center" style={{padding:'5px'}}>
                <Col md="4">
                <span 
                            onClick={()=> dispatch(removeCartItem(product.id))}
                            style={{marginLeft:'10px',fontSize: '30px', color:'red'}}><TiDeleteOutline/></span>
                    <img src={PRODUCT_HOST+product.images[0].image_url} style={{width:'80px', height:'80px'}}/></Col>
                <Col md="2"><div style={{fontWeight:'bold'}}>{product.product_name}</div></Col>
                <Col md="2"><div>{lang.priceLabel}: <span style={{color:'green'}}>
                ${discountedPrice(product)}
                    </span></div></Col>
               
                <Col md="2"><div>{lang.deliveryFeeLabel}: <span style={{color:'green'}}>${product.delivery_charges}</span></div></Col>
                <Col md="2">
                    {quantityColumn(cartItem, dispatch)}
                </Col>
                
            </Row>
        </>
    )
}

function quantityColumn(cartItem, dispatch){
    return (
        <div style={{minWidth:'150px', margin:'auto'}}>
            {/* QUANTITY BUTTONS */}
                <div style={{textAlign:'left'}}> <span style={{fontSize:'20px'}}></span></div>
                    <div style={{textAlign:'left'}}>
                        <span style={{textAlign:'left', backgroundColor:'white',borderRadius:'8px 8px', paddingLeft:'3px', paddingRight:'3px', paddingTop:'10px', paddingBottom:'8px'}}>
                            <span 
                        
                            onClick={()=> changeItemQuantity(cartItem, dispatch, 'inc')}
                            style={{fontSize: '30px', color:'orange', marginRight:'10px'}}><CgAdd/></span>
                            <span>{cartItem.quantity}</span>
                            <span 
                            onClick={()=> changeItemQuantity(cartItem, dispatch, 'dec')}
                            style={{marginLeft:'10px',fontSize: '30px', color:'orange'}}><BiMinusCircle/></span>
                        </span>
                    </div>
                    <div style={{textAlign:'left', marginTop:'20px'}}></div>
        </div>
    )
}


function pickYourself(cartItems, sellerAddresses, user, history, lang) {
    const subtotal = subTotalAmount(cartItems)
    const totalDelivery = 0
    const totalAmount = subtotal + totalDelivery
    let pickupLocation = ''
    if (sellerAddresses !== undefined){
        pickupLocation = sellerAddresses[0]
    }
    let userId = user === undefined ? '': user.data !== undefined ? user.data.id : user.user.id

    let areDifferentSellers = false
    if (cartItems.length > 0){
        const firstSellerId = cartItems[0].product.created_by
        cartItems.forEach(element => {
            if (element.product.created_by !== firstSellerId){
                areDifferentSellers = true
            }
        });
    }
    return (
        <div>
            {!areDifferentSellers ?
             <div style={{textAlign:'left', padding:'5px'}}><h5 style={{marginBottom:'5px'}}>{lang.selectPickupLocationLabel}:</h5>
                 <div onChange={(e)=>{pickupLocation = e.target.value}}>
                    {sellerAddresses !== undefined ? 
                        sellerAddresses.map((add, i)=>(
                            <div key={i} style={{padding: '5px', border:'1px solid #ccc', marginBottom:'4px'}}>
                                { i === 0 ? <><input type="radio" value={add} name="pickaddress" defaultChecked="1"/><span style={{paddingLeft:'5px'}}>{add}</span></>
                                    :<><input type="radio" value={add} name="pickaddress"/><span style={{paddingLeft:'5px'}}>{add}</span></>
                                }
                                
                            </div>
                        ))
                    :''}
                 </div>
                 {/*Sub total */}
                {amountDetailRow('Subtotal', subtotal)}
                 {/*Delivery Fee */}  
                {amountDetailRow('Delivery Fee', totalDelivery)}
                {/*Total Amount */}
                <div style={{height:'1px', backgroundColor:'black'}}></div>
                {amountDetailRow('Total Amount', totalAmount)}
                    <button 
                        onClick={async ()=>{
                            const ids = await placeOrder(userId, cartItems, '', totalAmount, totalDelivery, pickupLocation)
                            history.push(`/paymentmode/${totalAmount}/${ids}`)
                        }}
                        className="button" style={{height:'45px'}}>
                            <span>{lang.checkoutLabel}</span>
                    </button>
             </div>
             
            : <div>{lang.pickupNotAvailable}</div>}
        </div>
    )
}

function needDelivery(cartItems, addresses, user, history, lang){
    const subtotal = subTotalAmount(cartItems)
    const totalDelivery = totalDeliveryFee(cartItems)
    const totalAmount = subtotal + totalDelivery
    let deliveryAddress = ''
   
    let userId = user === undefined ? '': user.data !== undefined ? user.data.id : user.user.id
    console.log(user)
    let userAddress = ''
    if (user !== undefined){
        userAddress = user.data !== undefined ? user.data.address : user.user.address
        deliveryAddress = userAddress
    }
   

    return (
        <div style={{textAlign:'left', padding:'5px', marginBottom:'5px'}}>Select Delivery Address:

                <div onChange={(e)=>{deliveryAddress = e.target.value}}>
                    {userAddress != '' ? <div style={{padding: '5px', marginBottom:'4px',border:'1px solid #ccc'}}><input type="radio" value={userAddress} name="deladdress" defaultChecked="1"/><span style={{paddingLeft:'5px'}}>{userAddress}</span></div>:''}
                    {addresses !== undefined ? 
                        addresses.map((add, i)=>(
                            <div key={i} style={{padding: '5px', border:'1px solid #ccc', marginBottom:'4px'}}>
                                <input type="radio" value={add} name="deladdress" /><span style={{paddingLeft:'5px'}}>{add}</span>
                            </div>
                        ))
                    :''}
                </div>
                
                 
                 {/*Sub total */}
                {amountDetailRow('Subtotal', subtotal)}
                 {/*Delivery Fee */}  
                {amountDetailRow('Delivery Fee', totalDelivery)}
                {/*Total Amount */}
                <div style={{height:'1px', backgroundColor:'black'}}></div>
                {amountDetailRow('Total Amount', totalAmount)}
                    <button 
                        onClick={async () =>{
                            const ids = await placeOrder(userId, cartItems, deliveryAddress, totalAmount, totalDelivery, '')
                            history.push(`/paymentmode/${totalAmount.toFixed(2)}/${ids}`)
                        }}
                        className="button" style={{height:'45px'}}>
                            <span>{lang.checkoutLabel}</span>
                    </button>
             </div>
    )
}

function amountDetailRow(title, amount){
    return (
        <div style={{backgroundColor:'#eee', display:'flex'}}>
                 <div style={{padding:'10px', textAlign:'left', width:'50%'}}>
                 <div style={{fontWeight:'bold'}}>{title}</div>
            </div>
                <div style={{padding:'10px', textAlign:'right', width:'50%'}}>
                <div style={{fontWeight:'bold'}}>${amount.toFixed(2)}</div>
             </div>
       </div> 
    )
}

async function placeOrder(customerId, cartItems, address, totalAmount, deliveryFee, pickAddress){
    const sellersIds = sellerIds(cartItems)

    if (sellersIds.length === 1){
        // Single Order
        let items = extractItems(cartItems)
        const deliveryAddress = address+';32.32352;74.32342'

        const response = await placeOrders([new OrderToPlace(customerId, totalAmount, deliveryFee, deliveryAddress, pickAddress, items)])
        console.log(response.data)
        return response.data.data
    } else if (sellersIds.length > 1) {
        // Multiple Orders
        let orders = []
        sellersIds.forEach(sellerId => {
            const sellerItems = cartItems.filter(item => item.product.created_by === sellerId)
            let items = extractItems(sellerItems)
            let deliveryAddress = ''

            if (address !== ''){
                deliveryAddress = address+';32.32352;74.32342'
            }
            
            const subtotal = subTotalAmount(sellerItems)
            let delTotal = totalDeliveryFee(sellerItems)

            let total = 0
            if (address === ''){
                total = subtotal
                delTotal = 0
            } else {
                total = subtotal + delTotal
            }

            orders.push(new OrderToPlace(customerId, total, delTotal, deliveryAddress, pickAddress, items))
        });

        const response = await placeOrders(orders)
        console.log(response.data)
        return response.data.data
    }
    
}

function extractItems(cartItems){
    let items = []
    cartItems.forEach(element => {
        const id = element.product.id
        const image = element.product.images[0].image_url
        const name = element.product.product_name
        const quantity = element.quantity
        const price = element.product.price
        const deliveryCharge = element.product.delivery_charges
        items.push({id: id, image:image, name: name, quantity: quantity, price: price, deliveryCharge: deliveryCharge})
    });
    return items
}

function sellerIds(cartItems){
    let sellerIds = []
    cartItems.forEach(element => {
        const sellerId = element.product.created_by
        const ids = sellerIds.filter(id => id === sellerId)
        if (ids.length === 0){
            sellerIds.push(sellerId)
        } else {
            console.log('present')
        }
    });
    return sellerIds
}