import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {Form} from 'react-bootstrap'
import CountrySelect from 'react-bootstrap-country-select';

import { getBanners, getCategories } from '../redux/ducks/category_reducer';
import { getHomeProducts} from '../redux/ducks/products_reducer';

import Banners from '../components/homepage/banners';
import Categories from '../components/homepage/categories';
import ItemsCarousel from '../components/homepage/items_carousel';
import { CustomLoader } from '../components/loader';
import { setCountry } from '../redux/ducks/user_reducer';
import { selectdLocale } from '../langs/locale';

export default function HomePage() {
    const [ value, setValue ] = useState(null)
    const dispatch = useDispatch()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    const country = useSelector(state => state.user.country)

    dispatch(getBanners())
    dispatch(getCategories())
    
    if (country !== undefined){
      dispatch(getHomeProducts(country))
    }

    if (country === undefined || country === ''){
      return (
        <div style={{margin:'5%', minHeight:'63vh'}}>
          <div style={{textAlign:'center'}}><Form.Label>{lang.selectCountryLabel}</Form.Label></div>
                   <CountrySelect
                        value={value}
                        onChange={(value)=>dispatch(setCountry(value.name))}
                    />
        </div>
      )
    }
    
    return (
        <>
          <Banners/>
          <div style={{position:'relative'}}><Categories/></div>
          <ItemsCarousel title="top"/>
          <ItemsCarousel title="discounted"/>
          <ItemsCarousel title="new"/>
        </>
    )
}
