import { useState } from 'react'
import validateInfo from './validateInfo';
import { payWithStripe } from './redux/sagas/requests/api_requests';
import { useHistory } from 'react-router';

const useForm = () => {
    let history = useHistory()
    const [values, setValues] = useState({
        cardName: '',
        cardNumber: '',
        cardExpiration: '',
        cardSecurityCode: '',
        focus: ''
    })

    const [errors, setErrors] = useState({})

    const handleFocus = (e) => {
        setValues({ 
            ...values,
            focus: (e.target.name === 'cardSecurityCode') ? 'cvc' : e.target.name
        });
    }

    const handleChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = async (e, amount, ids) => {
        e.preventDefault()
        setErrors(validateInfo(values))
        if (validateInfo(values).variant.toString() === 'success'){
            console.log('call apis' + amount)
            let month = values.cardExpiration.split('/')[0]
            let year = values.cardExpiration.split('/')[1]
            let response = await payWithStripe(values.cardNumber, month, year, values.cardSecurityCode, amount, 'stripe payment', ids)
            if (response.data.status === "success"){
                history.push('/paymentsuccesspage')
            } else {
                history.push('paymentfailedpage')
            }
        }
    };
    
    return { handleChange, handleFocus, handleSubmit, values, errors };
};

export default useForm;