import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestMoreBySeller } from '../redux/sagas/requests/api_requests'
import { Product } from '../components/product'
import {Row} from 'react-bootstrap'
import { Loader } from '../components/loader'
import {setProdcutToOpen} from '../redux/ducks/products_reducer'
import { useHistory } from 'react-router'
import { selectdLocale } from '../langs/locale'

export default function MyItems() {
    const [items, setItems] = useState(undefined)
    const userData = useSelector(state => state.user.user)

    const lang = selectdLocale(useSelector(state => state.user.locale))
    const history = useHistory()
    let user = undefined
    const dispatch = useDispatch();

    useEffect(() => {
        if (userData !== undefined){
            if (userData.user !== undefined){
                user = userData.user
            } else {
                user = userData.data
            }
        }
    }, [userData])

    useEffect(async() => {
        if (user !== undefined){
            const response = await requestMoreBySeller(user.id)
            console.log(response.data.data)
            setItems(response.data.data)
        } 
    }, [user])

    if (items === undefined){
        return (
            <div style={{minHeight:'73vh'}}>
                {Loader()}
            </div>
        )
    }

    if (items.length === 0){
        return (<div style={{minHeight:'77vh', paddingTop:'100px'}}>
            <div style={{padding:'15px'}}>
                <button onClick={()=>{
                        dispatch(setProdcutToOpen(undefined))
                        history.push('/additem')
                    }} className="button" style={{width:'200px', backgroundColor: 'green'}}>
                    <span>{lang.addNewItemLabel}</span> 
                </button>
            </div>
            <h4>{lang.youHaveOItemsLabel}</h4>
        </div>)
    }

    function handleClick(id, prod) {
        dispatch(setProdcutToOpen(prod))
        history.push('/additem')
    }

    return (
        <div style={{minHeight:'73vh'}}>
            <div style={{padding:'15px'}}>
                <button onClick={()=>{
                        dispatch(setProdcutToOpen(undefined))
                        history.push('/additem')
                    }} className="button" style={{width:'200px', backgroundColor: 'green'}}>
                    <span>{lang.addNewItemLabel}</span> 
                </button>
            </div>
            <Row>
                { items !== undefined ?
                    items.map((p, i)=>(
                        <div key={i} style={{position:'relative',height:'420px', width:'290px', marginLeft:'15px'}}>                             
                            {Product(p, handleClick)}
                        </div>)):''
                }
            </Row>
        </div>
    )
}
