export const en = {
    homelabel: 'Home',
    categoriesLabel:'Categories',
    aboutustext: 'About Us',
    myOrdersLabel: 'Orders',
    myProfileLabel: 'Profile',
    englishLabel: 'English',
    spanishLabel: 'Spanish',
    loginLabel: 'Login',
    logoutLabel: 'Logout',
    aboutUs: 'About Us',
    contactUs: 'Contact Us',
    languageLabel: 'Language',
    accountText: 'Account',
    myItemsLabel: 'Items',
    uploadedSuccessfullyLabel: 'Uploaded successfully',
    failedToUploadLabel: 'Failed to upload!',
    mandatoryFieldsLabel: 'Images, title, category, subcategory, price, stock, deliveryfee are mandatory',
    updatedSuccessfullyLabel: 'Updated successfully',
    failedToUpdateLabel: 'Failed to update!',
    closeLabel: 'Close',
    selectImagesLabel: 'Select Images',
    productTitleLabel: 'Product title',
    categoryLabel: 'Category',
    selectCategoryLabel: 'Select Category',
    subcategoryLabel: 'Subcategory',
    selectSubcategoryLabel: 'Select Subcategory',
    descriptionLabel: 'Description',
    productDescriptionLabel: 'Product description',
    pricingStockLabel: 'Pricing & Stock',
    priceLabel: 'Price',
    stockLabel: 'Stock',
    discountLabel: 'Discount',
    deliveryLabel: 'Delivery',
    deliveryChargesLabel: 'Delivery Charges',
    updateProductLabel: 'Update Product',
    uploadProductLabel: 'Upload Product',
    addNewItemLabel: 'Add new item',
    orderPickRequestLabel: 'Order Pick Request',
    youHaveOItemsLabel: 'You have 0 items.',
    insightLabel: 'Insight',
    myEarningsLabel: 'Earnings',
    deletProductLabel: 'Delete Item',
    distanceLabel: 'Distance',
    requestToPickOrderLabel: 'Request to pick order',
    orderStatusLabel: 'Order Status',
    paymentLabel: 'Payment',
    recentTransactionsLabel: 'Recent Transactions',
    revenueLabel: 'Revenue',
    earningsLabel: 'Earnings',
    orderStatisticsLabel: 'Order Statistics',
    totSellingProductsLabel: 'Top Selling Products',
    ordersLabel: 'Orders',
    pendingLabel: 'Pending',
    noOfSoldsLabel: 'No. of Solds',
    requestSentSuccessLabel: 'Request sent successfully',
    failedToSendRequestLabel: 'Failed to send request!',
    orderIdLabel: 'Order ID',
    orderedOnLabel: 'Ordered On',
    requestSentToLabel: 'Request sent to',
    makeRequestToOtherLabel: 'Make request to other'
}