import React, {useState, useEffect} from 'react'
import {Row} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Carousel from "react-simply-carousel"

export default function Categories() {
    const [categories, setcategories] = useState([])
    const cats = useSelector(state => state.category.categories)
    let history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        if (cats !== undefined){
            setcategories(cats.data)
        }
    }, [cats])
    
    
    return (         
         <div className="cats-wrapper">
            {
                    categories.map((cat, ind)=>(
                        <div 
                        onClick={()=>{
                            history.push('/category/products/'+cat.id)
                        }}
                        key={ind}
                        className="cat-item"
                        style={{cursor:'pointer',height:'220px', minWidth:'170px', position:'relative', marginTop:'20px', marginBottom:'10px', marginRight:'10px', borderRadius:'10px 10px 0px 0px'}}>
                            <div style={{height:'80%', width:'100%', position:'absolute', left:'0', top:'0'}}>
                                <img src={cat.image_url} style={{width:'100%', height:'95%', borderRadius:'10px 10px 0 0'}}/>
                            </div>
                            <div style={{paddingTop:'5px',height:'25%', width:'100%', position:'absolute', left:'0', bottom:'0'}}>
                                <h5>{cat.category_name}</h5>
                            </div>
                        </div> 
                    ))
                }
            
        </div>)
            
             {/* {
                      categories.map((cat, index)=>(
                            <Link key={index} to={`${process.env.PUBLIC_URL}/product/category/${cat.id}`}>
                                
                                <Row style={{backgroundColor:'#edeff2', paddingLeft: '5px', paddingTop:'8px',marginBottom: '2px'}}>
                                    <Col md = "2">
                                        <img src={cat.image_url} style={{width:'30px', height:'30px', borderRadius:'5px 5px'}}/>
                                    </Col>
                                    <Col md = "10">
                                     <h4 style={{color:'orange'}}>{cat.category_name}</h4>
                                    </Col>
                                </Row>
                                
                            </Link>
                            
                      ))
                  } */}             
    // )
}



