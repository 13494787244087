import './App.css';

import React, {useState} from 'react';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { Route, Switch } from 'react-router-dom';

import LoginSignUp from './pages/login_sign_up';
import MyAccount from './pages/my_account';
import MyOrders from './pages/my_orders';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contactus';
import TermsConditions from './pages/terms_conditions';
import PrivacyPolicy from './pages/privacy_policy';
import {useDispatch} from 'react-redux'
import { getCategories } from './redux/ducks/category_reducer';
import MyItems from './pages/MyItems';
import AddItem from './pages/AddItem';
import OrderPickRequest from './pages/OrderPickRequest';
import Earnings from './pages/Earnings';
import Insight from './pages/Insight';


function App() {
  console.log('App js is called.........')
  const dispatch = useDispatch()
  dispatch(getCategories())

  return (
    <div className="App">
      <Header/>
      <Switch>
        <Route exact path='/' component={MyOrders}/>
        <Route exact path='/myitems' component={MyItems}/>
        <Route exact path='/additem' component={AddItem}/>
        <Route exact path='/earning' component={Earnings}/>
        <Route exact path='/insight' component={Insight}/>
        <Route exact path='/orderpickrequest/:id' component={OrderPickRequest}/>
        <Route exact path='/termsconditions'><TermsConditions/></Route>
        <Route exact path='/privacypolicy'><PrivacyPolicy/></Route>
        <Route exact path='/login'><LoginSignUp/></Route>
        <Route exact path='/about'><AboutUs/></Route>
        <Route exact path='/contact'><ContactUs/></Route>
        <Route exact path='/myaccount'><MyAccount/></Route>
      </Switch>

      <Footer/>
    </div>
  );
}

export default App;
