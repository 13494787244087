import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const GET_CATEGORIES = 'getcategories'
const SET_CATEGORIES = 'setcategories'

export const GET_SUB_CATEGORIES = 'getsubcategories'
const SET_SUB_CATEGORIES = 'setsubcategories'

export const GET_BANNERS = 'getbanners'
const SET_BANNERS = 'setbanners'

export const getCategories = () => ({
    type: GET_CATEGORIES
})

export const setCategories = (categories) => ({
    type: SET_CATEGORIES,
    categories
})

export const getSubcategories = (categoryId) => ({
    type: GET_SUB_CATEGORIES,
    categoryId
})

export const setSubcategories = (subcategories) => ({
    type: SET_SUB_CATEGORIES,
    subcategories
})

export const getBanners = () => ({
    type: GET_BANNERS
})

export const setBanners = (banners) => ({
    type: SET_BANNERS,
    banners
})

const initialState = {
    categories: undefined,
    subcategories: undefined,
    banners: undefined
}

const categoryReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CATEGORIES:
            const {categories} = action;
            return {...state, categories}  
        case SET_SUB_CATEGORIES:
            const {subcategories} = action;
            return {...state, subcategories} 
        case SET_BANNERS:
            const {banners} = action;
            return {...state, banners}           
        default:
            return state;    
    }
}

const persistConfig = {
    keyPrefix: "panda-",
    key: "categories",
    storage
}

export default persistReducer( persistConfig, categoryReducer );