import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearCart } from '../redux/ducks/cart_reducer'
import { useHistory } from 'react-router'
import {IoCheckmarkDone} from 'react-icons/io5'
import { selectdLocale } from '../langs/locale'

export default function PaymentSuccess() {
    const dispatch = useDispatch()
    let history = useHistory()
    const lang = selectdLocale(useSelector(state => state.user.locale))
    dispatch(clearCart())
    return (
        <div style={{minHeight:'77vh', padding:'100px'}}>
            <span>{statusCircle('green', 'white', 'Placed')}</span>
            <br/>
            <h4>{lang.orderPlacedSuccessLabel}</h4>
            <h5>{lang.goToLabel} <span 
            onClick={()=>history.push('/myorders')}
            style={{color:'orange', cursor:'pointer'}}>{lang.myOrdersLabel}</span> {lang.pageLabel}.</h5>
        </div>
    )
}

function statusCircle(color, textColor, title){
    return (
        <div className="status-circle" style={{margin:'auto', backgroundColor:color}}>
                <div style={{position:'relative', top:'20px'}}>
                  <div><IoCheckmarkDone style={{fontSize:'25px', color:textColor}}/></div>
                  <div style={{color:textColor}}>{title}</div>
               </div>
        </div>
    )
}
