import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row, Col } from 'react-bootstrap'
import { CgAdd } from 'react-icons/cg';
import { BiMinusCircle } from 'react-icons/bi'
import { MdStars } from 'react-icons/md';
import { FaStar } from 'react-icons/fa'
import { getSellerProducts } from '../redux/ducks/products_reducer';
import { Product } from '../components/homepage/product';
import { addCartItem, addWishItem, } from '../redux/ducks/cart_reducer';
import CartItem from '../Models/cart_item_model'
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    WhatsappIcon,
} from "react-share";
import { PRODUCT_HOST } from '../constants';
import { getProductByID, productReviews } from '../redux/sagas/requests/api_requests';
import { CustomLoader } from '../components/loader';
import { setCountry } from '../redux/ducks/user_reducer';
import { Form } from 'react-bootstrap'
import CountrySelect from 'react-bootstrap-country-select';
import { selectdLocale } from '../langs/locale';

export default function ProductInfo(props) {
    const [value, setValue] = useState(null)
    const [moreProducts, setMoreProducts] = useState([])
    const [product, setProduct] = useState(useSelector(state => state.product.productToOpen))
    const [quantity, setQuantity] = useState(1)
    const [reviews, setReviews] = useState(undefined)
    const dispatch = useDispatch();

    const productID = props.match.params.id
    const country = useSelector(state => state.user.country)

    const lang = selectdLocale(useSelector(state => state.user.locale))

    useEffect(() => {
        window.scrollTo(0, 0)
        if (product !== undefined) {
            console.log('products dispatched')
            dispatch(getSellerProducts(product.sellerinfo[0].id))
        }
    }, [product])

    useEffect(async () => {
        if (product !== undefined) {
            const response = await productReviews(product.id)
            console.log(response.data.data)
            setReviews(response.data.data)
        }
    }, [product])

    const moreProds = useSelector(state => state.product.moreProducts)

    useEffect(() => {
        if (moreProds !== undefined) {
            setMoreProducts(moreProds.data)
        }

    }, [moreProds])

    useEffect(async () => {
        if (product === undefined) {
            const response = await getProductByID(productID)
            console.log(response.data.data)
            if (response.data !== undefined) {
                if (response.data.data[0] !== undefined) {
                    setProduct(response.data.data[0])
                }
            }
        }
    }, [])

    if (country === undefined || country === '') {
        return (
            <div style={{ margin: '200px', minHeight: '20vh' }}>
                <div style={{ textAlign: 'center' }}><Form.Label>{lang.selectCountryLabel}</Form.Label></div>
                <CountrySelect
                    value={value}
                    onChange={(value) => dispatch(setCountry(value.name))}
                />
            </div>
        )
    }

    if (country !== undefined && product === undefined) {
        return CustomLoader()
    }

    return (
        <>
            <Row>
                <Col md="6">
                    <Carousel>
                        {
                            product.images.map((img, ind) => (
                                <div key={ind} >
                                    <img style={{ maxHeight: '600px' }} src={img.image_url.includes('https') ? img.image_url : 'https://paolashopping.com/apis/storage/media/Products/' + img.image_url} />
                                </div>
                            ))
                        }

                    </Carousel>
                </Col>
                <Col md="6" style={{ backgroundColor: '#EEE', paddingLeft: '30px', paddingTop: '40px' }}>
                    <div style={{ textAlign: 'left' }}><h3>{product.product_name}</h3></div>
                    <div style={{ textAlign: 'left', color: 'grey' }}><h6>{product.sellerinfo[0].full_name}</h6></div>

                    <div style={{ textAlign: 'left' }}>{lang.descriptionLabel}:</div>
                    <div style={{ textAlign: 'left', color: 'grey', backgroundColor: 'white', padding: '8px', marginRight: '40px', marginTop: '3px', borderRadius: '5px 5px' }}><p>{product.description}</p></div>
                    <br />
                    {/* Price  */}
                    <div style={{ textAlign: 'left' }}><h5>{lang.priceLabel}: <span style={{ color: 'green' }}>${product.discount > 0 ? (product.price - (product.price * (product.discount / 100))).toFixed(2) : product.price}</span></h5></div>

                    {/* QUANTITY BUTTONS */}
                    {quantityColumn(product.stock_unit, quantity, setQuantity)}
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>

                        {/* ADD TO CART */}
                        <button
                            onClick={() => {
                                dispatch(addCartItem(new CartItem(product, quantity)))
                            }}
                            className="button" style={{ width: '150px' }}><span>{lang.addToCartLabel}</span></button>

                        {/* ADD TO WISHLIST */}
                        <button
                            onClick={() => {
                                dispatch(addWishItem(product))
                            }}
                            className="button" style={{ width: '200px' }}><span>{lang.addToWishListLabel}</span></button>

                        {shareButtons(product.id, product.product_name, product.images != 0 ? product.images[0].image_url : "", lang)}
                    </div>
                </Col>
            </Row>
            {reviews !== undefined ? ratingsAndReviews(reviews, lang) : ''}

            <Row style={{ margin: '20px' }}>
                <div style={{ textAlign: 'left', backgroundColor: '#EEE', padding: '10px', marginBottom: '20px', fontWeight: 'bold' }}>{lang.moreBySellerLabel}</div>
                {moreProducts.map((p, i) => (
                    <div style={{ height: '410px', width: '290px', marginLeft: '15px' }}>
                        {Product(p, () => {
                            window.scrollTo(0, 0)
                            setProduct(p)
                        })}
                    </div>
                ))}
            </Row>
        </>
    )
}

function quantityColumn(stock, quantity, setQuantity) {
    return (
        <div style={{ minWidth: '150px', margin: 'auto' }}>
            {/* QUANTITY BUTTONS */}
            <div style={{ textAlign: 'left' }}> <span style={{ fontSize: '20px' }}></span></div>
            <div style={{ textAlign: 'left' }}>
                <span style={{ textAlign: 'left', backgroundColor: 'white', borderRadius: '8px 8px', paddingLeft: '3px', paddingRight: '3px', paddingTop: '10px', paddingBottom: '8px' }}>
                    <span

                        onClick={() => {
                            if (quantity < stock)
                                setQuantity(quantity + 1)
                        }}
                        style={{ fontSize: '30px', color: 'orange', marginRight: '10px' }}><CgAdd /></span>
                    <span>{quantity}</span>
                    <span
                        onClick={() => {
                            if (quantity > 1)
                                setQuantity(quantity - 1)
                        }}
                        style={{ marginLeft: '10px', fontSize: '30px', color: 'orange' }}><BiMinusCircle /></span>
                </span>
            </div>
            <div style={{ textAlign: 'left', marginTop: '20px' }}></div>
        </div>
    )
}

function ratingsAndReviews(reviews, lang) {
    let starCounts = [0, 0, 0, 0, 0]
    let reviewCountPercent = [0.0, 0.0, 0.0, 0.0, 0.0]
    let totalRating = 0
    let totalCount = 0
    reviews.forEach(review => {
        totalRating += review.rating
        totalCount += 1
        if (review.rating !== 0) {
            starCounts[review.rating - 1] += 1
        }
    });

    for (let i = 0; i < reviewCountPercent.length; i++) {
        reviewCountPercent[i] = starCounts[i] / totalCount
        console.log(starCounts[i])
    }

    let avgRating = 0
    if (totalCount !== 0) {
        avgRating = totalRating / totalCount
    }

    return (
        <div style={{ margin: '20px', width: '90%', borderRadius: '5px 5px', border: '1px solid #EEE', height: '500px', overflowY: 'auto' }}>
            <div style={{ textAlign: 'left', margin: '10px 0px 0px 10px', fontWeight: 'bold' }}>{lang.ratingsReviewsLabel}</div>
            <hr />
            {/* Rating Box */}
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '50px 0px 20px 80px' }}>
                    <div><h5>{lang.avgRatingLabel}</h5></div>
                    <div><h5>{avgRating.toFixed(1)} <MdStars style={{ color: 'orange' }} /></h5> </div>
                    <div><h5>{lang.totalLabel}: {totalCount}</h5></div>
                </div>
                <div style={{ margin: '20px 0px 20px 100px' }}>
                    {progressBar(starCounts[4], 5, reviewCountPercent[4])}
                    {progressBar(starCounts[3], 4, reviewCountPercent[3])}
                    {progressBar(starCounts[2], 3, reviewCountPercent[2])}
                    {progressBar(starCounts[1], 2, reviewCountPercent[1])}
                    {progressBar(starCounts[0], 1, reviewCountPercent[0])}
                </div>
            </div>
            {
                reviews !== undefined ?
                    reviews.map((review, index) => (
                        userReview(review, index)
                    ))
                    : ''
            }

        </div>
    )
}

function progressBar(starcount, num, percentCount) {
    //console.log(percentCount)
    return (
        <>
            <div style={{ display: 'flex' }}><span>{num} <MdStars style={{ color: 'orange', fontSize: '20px' }} /></span>
                <div style={{ margin: '5px 20px 20px 5px', width: '200px', backgroundColor: '#eee', height: '15px', borderRadius: '10px 10px' }}>
                    <div style={{ width: percentCount * 200, backgroundColor: 'orange', height: '15px', borderRadius: '10px 10px' }}></div>
                </div>
                <span>{starcount}</span></div>

        </>
    )
}

function userReview(review, index) {
    return (
        <div key={index} style={{ textAlign: 'left', width: '80%', margin: '20px', backgroundColor: '#eee', border: '1px solid #eee' }}>
            <div style={{ dispaly: 'flex', position: 'relative', padding: '10px' }}>
                <div>{review.userdetail.full_name}</div>
                <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    {ratingStarts(review.rating)}
                </div>
            </div>

            <div style={{ backgroundColor: 'white', padding: '10px' }}>{review.review}</div>
        </div>
    )
}


function ratingStarts(rating) {
    let arr = []
    for (let i = 0; i < 5; i++) {
        if (i < rating) {
            arr.push(<FaStar style={{ color: 'orange' }} />)
        } else {
            arr.push(<FaStar style={{ color: 'grey' }} />)
        }
    }
    return (arr)
}

function shareButtons(id, productName, imageUrl, lang) {
    let sharingLink = `https://pandashopinc.com/product/${id}`
    return (
        <div style={{ marginTop: '20px', marginLeft: '5px' }}>
            <h4>{lang.shareToLabel}</h4>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '7px' }}>
                    <EmailShareButton
                        url={sharingLink}
                        subject={productName}
                    >
                        <EmailIcon round="true"></EmailIcon>
                    </EmailShareButton>
                </div>
                <div>
                </div>

                <div style={{ margin: '7px' }}>
                    <FacebookShareButton
                        url={sharingLink}
                        quote={productName}
                    >
                        <FacebookIcon logoFillColor="white" round="true"></FacebookIcon>
                    </FacebookShareButton>
                </div>

                <div style={{ margin: '7px' }}>
                    <WhatsappShareButton
                        url={sharingLink}
                        title={productName}
                    >
                        <WhatsappIcon round="true"></WhatsappIcon>
                    </WhatsappShareButton>
                </div>
            </div>
        </div>

    )
}
