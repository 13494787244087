import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProdcutToOpen } from '../../redux/ducks/products_reducer';
import { Product } from './product';
import { selectdLocale } from '../../langs/locale';
import { CustomLoader } from '../loader';

export default function ItemCarousel(props) {
  const [title, settitle] = useState('')
  const [width, setWidth] = useState(window.innerWidth)
  const [products, setproducts] = useState([])

  const lang = selectdLocale(useSelector(state => state.user.locale))

  const dispatch = useDispatch();

  let history = useHistory()

  function handleClick(id, prod) {
    dispatch(setProdcutToOpen(prod))
    history.push(`/product/${id}`);
  }

  const prodsData = useSelector(state => state.product.homeProducts)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    if (prodsData !== undefined) {
      if (props.title === 'new') {
        settitle(lang.newlyLaunchedProducts)
        setproducts(prodsData.data.newly_launched)
        //console.log(prodsData.data.newly_launched)
      } else if (props.title === 'top') {
        settitle(lang.topRatedProducts)
        setproducts(prodsData.data.top_rated)
      } else {
        settitle(lang.discountedProducts)
        setproducts(prodsData.data.discounted)
      }
    }
  }, [prodsData])

  if (products === undefined) {
    return CustomLoader()
  }



  if (products.length === 0 && props.title === 'new') {
    return (<div style={{ paddingTop: '100px', minHeight: '77vh' }}>
      <h4>No product availabe for this country.</h4>
    </div>)
  } else if (products.length === 0) {
    return (<div></div>)
  }

  return (
    <>
      {products.length !== 0 ?
        <div style={{ textAlign: 'left', padding: '9px 0px 0px 15px', height: '50px', marginTop: '20px', marginBottom: '10px' }}>
          <span style={{ fontSize: '26px', fontWeight: 'bold', paddingLeft: '6px' }}>{title}</span>
        </div>
        : ''}

      <Row>
        {
          products.map((p, i) => (
            <div style={{ height: '410px', width: '290px', marginLeft: '15px' }}>
              {Product(p, handleClick)}
            </div>))
        }
      </Row>
    </>
  );
};