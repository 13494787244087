import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { CustomLoader } from '../loader';

const AutoplaySlider = withAutoplay(AwesomeSlider);


export default function Banners() {
    const [banners, setbanners] = useState([])
    const bans = useSelector(state => state.category.banners)

    useEffect(() => {
        if (bans !== undefined){
            console.log(bans.data)
            setbanners(bans.data)
        }
    }, [bans])

    if (banners.length === 0){
        return CustomLoader()
    }

    return (
        <div style={{height:'450px', width:'100%', backgroundColor:'#ccc'}}>
            <AutoplaySlider
                    play={true}
                    bullets={false}
                    
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    style={{backgroundColor:'white',width:'100%', height:'450px'}}
                >
                    {
                    banners.map((banner, index)=>(
                       
                            <div style={{height:'450px', width:'100%'}}>
                                <img
                                    style={{height:'100%', width:'100%'}}
                                    src={banner.image_url}
                                    alt="First slide"
                                />
                            </div>
                        
                    ))
                }
                </AutoplaySlider>
        </div>
    )
}
