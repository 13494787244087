import { call, put } from "@redux-saga/core/effects";
import { setBanners, setCategories, setSubcategories } from "../../ducks/category_reducer"
import { requestBanners, requestCategories, requestCategoriesAgainstCategory } from "../requests/products_req"


export function* handleGetCategories(){
    try{
        const response = yield call(requestCategories)
        const {data} = response
        yield put(setCategories(data))
    } catch(e){
        console.log(e)
    }
}

export function* handleSubcategoriesAgainstCategory(action){
    try{
        const response = yield call(requestCategoriesAgainstCategory, action.categoryId)
        const {data} = response
        yield put(setSubcategories(data))
    } catch(e){
        console.log(e)
    }
}

export function* handleGetBanners(){
    try{
        const response = yield call(requestBanners)
        const {data} = response
        yield put(setBanners(data))
    } catch(e){
        console.log(e)
    }
}
