
import React, {useState, useEffect} from 'react'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col } from 'react-bootstrap'
import {MdAddShoppingCart, MdFavoriteBorder} from 'react-icons/md'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setLocale, setUser } from '../../redux/ducks/user_reducer'
import { selectdLocale } from '../../langs/locale'
import search from '../../helper/search_helper'


export default function Header() {
  const [width, setWidth] = useState(window.innerWidth)
  
  const cartItems = useSelector(state => state.cartItem.cartItems)
  const wishItems = useSelector(state => state.cartItem.wishItems)
  const user = useSelector(state => state.user.user)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [categories, setCategories] = useState(undefined)
  const cats = useSelector(state => state.category.categories)

  let lang = selectdLocale(useSelector(state => state.user.locale))

  const dispatch = useDispatch()

  useEffect(() => {
    if (cats !== undefined){
      setCategories(cats.data)
    }
}, [cats])

  let history = useHistory()

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  // useEffect(() => {
  //     window.addEventListener('resize', handleResize)
  // },)

    return (
        <>
          <Navbar style={{backgroundColor:'#000000'}} expand="lg" bg="dark" variant="dark">
            <Navbar.Brand href="#home" style={{marginTop:'5px', fontWeight:'bold', color:'white'}}>
              <img src="/assets/images/splash_image.png" 
              style={{width:'45px', height:'35px', marginLeft:'15px', marginRight:'5px', color:'white'}}/>
              PANDA
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-md-center">
              <Nav className="mr-auto">
                <Nav.Link onClick={()=>history.push('/')}>{lang.homelabel}</Nav.Link>
                
                <Nav.Link href="/about">{lang.aboutustext}</Nav.Link>

                {categories !== undefined ? 
                  <NavDropdown title={lang.categoriesLabel} id="basic-nav-dropdown">
                    {categories.map((cat, index)=>(
                      <NavDropdown.Item key={index} href={`/category/products/${cat.id}`}>{cat.category_name}</NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  :''}
      
                {user !== undefined ? 
                  <NavDropdown title={lang.accountText} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/myorders">{lang.myOrdersLabel}</NavDropdown.Item>
                    <NavDropdown.Item href="/myaccount">{lang.myProfileLabel}</NavDropdown.Item>
                  </NavDropdown>
                  :''}


                <NavDropdown title={lang.languageLabel} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={()=>dispatch(setLocale('en'))}>{lang.englishLabel}</NavDropdown.Item>
                    <NavDropdown.Item onClick={()=>dispatch(setLocale('sp'))}>{lang.spanishLabel}</NavDropdown.Item>
                  </NavDropdown>
                {user !== undefined ?
                  <Nav.Link onClick={()=> {
                    dispatch(setUser(undefined))
                    history.push('/')
                  }}>{lang.logoutLabel}</Nav.Link>
                :<Nav.Link onClick={()=> history.push('/login')}>{lang.loginLabel}</Nav.Link>}
                
                
              </Nav>
              <div style={{display:'flex', marginLeft:'10px'}}>
                <FormControl 
                value={searchKeyword}
                onChange={(e)=>{
                  setSearchKeyword(e.target.value)
                  search.keyword = e.target.value
                }}
                type="text" placeholder={lang.searchLabel} className="mr-sm-2" style={{marginRight:'5px'}} />
                <Button 
                
                onClick={(e)=>{
                  let keyword = searchKeyword.replace(' ', '+')
                  history.replace(`/search/${keyword}`)
                  setSearchKeyword('')
                }}
                variant="outline-light">{lang.searchLabel}</Button>
              </div>
              <div style={{display:'flex', position:'absolute', top:'20px', right:(width>970?'50px':'100px')}}>
                <div style={{position: 'relative'}}>
                  {/* Cart Button */}
                  <a href="/cart">
                    <div style={{textAlign:'center',paddintBottom:'8px',position:'absolute',zIndex:'1', top:'0px', left:'20px', backgroundColor:'red', width:'16px', height:'16px', borderRadius:'8px 8px'}}>
                    <span style={{position:'relative', top:'-4px', fontSize:'13px', color:'white'}}>
                      {cartItems !== undefined ? `${cartItems.length}`:'0'}</span>
                    </div>
                    <div 
                    onClick={()=>history.push('/cart')}
                    style={{position:'absolute', left:'6px', top:'2px'}}>
                  <MdAddShoppingCart className="cartbtn" style={{fontSize:'23px'}}/></div></a>
                    {/* Favorite Button */}
                  <a href="/wishlist">
                  <div style={{position:'absolute',zIndex:'1', top:'0px', right:'4px', backgroundColor:'red', width:'16px', height:'16px', borderRadius:'8px 8px'}}>
                  <span style={{position:'relative', top:'-4px', fontSize:'13px', color:'white'}}>
                  {wishItems !== undefined ? `${wishItems.length}`:'0'}
                  </span>
                  </div>
                  <div 
                    onClick={()=>history.push('/wishlist')}
                    style={{position:'absolute', right:'10px', top:'2px'}}>
                    <MdFavoriteBorder className="cartbtn" style={{fontSize:'23px'}}/></div></a>
                </div>
              </div>
            </Navbar.Collapse>
            
          </Navbar>
        </>
    )
}
