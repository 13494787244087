import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {Col, Row, Form, Button} from 'react-bootstrap'
import CountrySelect from 'react-bootstrap-country-select';
import { loginUser, registerUser, socialLogin } from '../redux/sagas/requests/api_requests';
import { setUser } from '../redux/ducks/user_reducer';
import { useHistory } from 'react-router';
import { GoogleLogin } from 'react-google-login';
import { AbsoluteLoader } from '../components/loader';
import {selectdLocale} from '../langs/locale'

export default function LoginSignUp(props) {
    const [ value, setValue ] = useState(null)
    const [loginEmail, setloginEmail] = useState('')
    const [loginPassword, setloginPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [registerError, setRegisterError] = useState('')

    const [showLoader, setShowLoader] = useState(false)
    
    // Register Data
    const [fullname, setFullname] = useState('')
    const [regEmail, setRegEmail] = useState('')
    const [regPassword, setRegPassword] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [socialId, setsocialId] = useState('')
    const [firstSocialLogin, setFirstSocialLogin] = useState('')

    const dispatch = useDispatch()
    let history = useHistory()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    const responseGoogle = async (response) => {
        const profile = response.profileObj
        if (profile !== undefined){

            try {
                const logResponse = await socialLogin(profile.googleId) 
                if (logResponse.data.status === 'success'){
                    dispatch(setUser(logResponse.data.data))
                    console.log(logResponse.data.data)
                    history.goBack()
                }
            } catch(e){
                console.log(e)
                setFirstSocialLogin(lang.registerFirstTimeLabel)
                setRegEmail(profile.email)
                setFullname(profile.name)
                setsocialId(profile.googleId)
            }

        }
        //console.log(profile)
      }

    if (props.isFromCart === undefined){
        console.log('not from cart')
    }
    if (props.isFromCart !== undefined){
        console.log('this is from cart')
    }

    // Login
    const handleLogin = async () => {
        if (loginEmail !== '' && loginPassword !== ''){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( re.test(loginEmail)) {
                if (loginPassword.length >= 6){
                    // Login Call
                    setShowLoader(true)
                    try{
                        let response = await loginUser(loginEmail, loginPassword)
                        setShowLoader(false)
                        if (response.data.status === 'success'){
                            dispatch(setUser(response.data.data))
                            console.log(response.data.data)
                            history.goBack()
                        } else {
                            setLoginError(lang.emailOrPasswordWrongLabel)
                        }
                    } catch(e){
                        setShowLoader(false)
                        setLoginError(lang.emailOrPasswordWrongLabel)
                    }
                    
                    
                } else {
                    setLoginError(lang.mustBeSixCharactersLabel)
                    console.log('invalid password!')
                }
               
            }
            else {
                setLoginError(lang.invalidEmailLabel)
                console.log('invalid email!')
            }
        } else {
            setLoginError(lang.bothFieldsRequiredLabel)
        }
    }

    // Registration
    const handleRegister = async () => {
        let password = regPassword
        if (socialId !== ''){
            password = '889908883'
        }
        if (fullname !== '' && regEmail !== '' && password !== '' && value !== null && phoneNo !== '' && address !== ''){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( re.test(regEmail)) {
                if (password.length >= 6){
                    setShowLoader(true)
                    // register Call
                    let response = await registerUser(
                        fullname, regEmail, regPassword, value.name, phoneNo, address, socialId
                    )
                    setShowLoader(false)
                    if (response.data.status === 'success'){
                        dispatch(setUser(response.data))
                        console.log(response.data)
                        history.goBack()
                    } else {
                        setRegisterError(lang.emailExistsLabel)
                    }
                   
                } else {
                    setRegisterError(lang.mustBeSixCharactersLabel)
                    console.log('invalid password!')
                }
               
            }
            else {
                setRegisterError(lang.invalidEmailLabel)
                console.log('invalid email!')
            }
        } else {
            setRegisterError(lang.allFieldsRequiredLabel)
        }
    }

    return (
        <div>
        {showLoader ? AbsoluteLoader(): ''}
        <Row>
            {/* Login */}
            <Col md="4" style={{padding:'40px', backgroundColor:'#eee'}}>
                <Row>
                <div style={{fontSize:'25px', fontWeight:'bold'}}>{lang.loginLabel}</div>
                <div style={{color:'red', textAlign:'left'}}>{loginError}</div>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.emailAddressLabel}</Form.Label></div>
                        <Form.Control type="email" placeholder={lang.enterEmailLabel} onChange={(e)=>{
                            setLoginError('')
                            setloginEmail(e.target.value)}}/>
                        <Form.Text className="text-muted">
                        {lang.willNeverShareEmailLabel}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                    <div style={{textAlign:'left'}}><Form.Label>{lang.passwordLabel}</Form.Label></div>
                        <Form.Control type="password" placeholder={lang.passwordLabel} onChange={(e)=>{
                            setLoginError('')
                            setloginPassword(e.target.value)}}/>
                    </Form.Group>
                   <div style={{cursor:'pointer', color:'blue', textAlign:'right', margin:'5px'}}>{lang.forgotPasswordLabel}</div>
                   
                    </Form>
                    <div>
                    <button 
                        onClick={handleLogin}
                        className="button" style={{height:'45px'}}>
                            <span>{lang.loginLabel}</span>
                    </button>
                    </div>
                    <div style={{padding:'10px'}}>
                        {lang.orContinueWithLabel}
                    </div>
                    {
                        firstSocialLogin !== ''? <div style={{padding:'10px'}}>{firstSocialLogin}</div>:''
                    }
                    <div>
                        <GoogleLogin
                            clientId="571293784697-1rcdocqntadilo3ntp3q8duveilc3nsr.apps.googleusercontent.com"
                            render={renderProps => (
                                <div className='g-sign-in-button' onClick={renderProps.onClick}>
                                <div className='content-wrapper'>
                                    <div className='logo-wrapper'>
                                        <img src='https://developers.google.com/identity/images/g-logo.png'/>
                                    </div>
                                    <span class='text-container'>
                                <span>{lang.signInWithGoogle}</span>
                                </span>
                                </div>
                            </div>
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </Row>
            </Col>
            {/* Signup*/}
            <Col md="8" style={{padding: '40px 100px 40px 100px'}}>
                
                <Form>
                <div style={{fontSize:'25px', fontWeight:'bold'}}>{lang.registerLabel}</div>
                <div style={{color:'red', textAlign:'left'}}>{registerError}</div>
                    <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.fullNameLabel}</Form.Label></div>
                        <Form.Control type="text" 
                        value={fullname}
                        onChange={(e)=>setFullname(e.target.value)}
                        placeholder={lang.enterFullNameLabel} />
                        </Form.Group><br/>

                        <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.emailAddressLabel}</Form.Label></div>
                        <Form.Control value={regEmail} onChange={(e)=>setRegEmail(e.target.value)} type="email" placeholder={lang.enterEmailLabel} />
                        </Form.Group><br/>

                        <Form.Group as={Col} controlId="formGridPassword">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.notRequiredIfSocialLabel}</Form.Label></div>
                        <Form.Control onChange={(e)=>setRegPassword(e.target.value)} type="password" placeholder={lang.passwordLabel} />
                        </Form.Group><br/>
                    </Form.Row>

                    <Form.Group controlId="formGridAddress1">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.addressLabel}</Form.Label></div>
                        <Form.Control onChange={(e)=>setAddress(e.target.value)} placeholder={lang.enterAddressLabel} />
                    </Form.Group>
                    <br/>
                    <div style={{textAlign:'left'}}><Form.Label>{lang.countryLabel}</Form.Label></div>
                   <CountrySelect
                        value={value}
                        onChange={setValue}
                    />
                    <br/>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.phoneNoLabel}</Form.Label></div>
                        <Form.Control onChange={(e)=>setPhoneNo(e.target.value)} type="phone" placeholder={lang.enterPhoneNumberLabel} defaultValue={phoneNo}/>
                        </Form.Group>
                    <br/>
                    
                    </Form>
                    <div>
                        <button className="button" onClick={handleRegister}>
                        <span>{lang.signUpLabel}</span> 
                        </button>
                    </div>
            </Col>
        </Row>
        </div>
    )
}
