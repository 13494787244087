import { call, put } from "@redux-saga/core/effects";
import { setHomeProducts, setProducts, setSellerProducts } from "../../ducks/products_reducer";
import { requestHomeProducts, requestProductsByCategory, requestProductsBySeller } from "../requests/products_req";

export function* handleGetProducts(action){
    try{
        const response = yield call(requestProductsByCategory, action)
        const {data} = response
        yield put(setProducts(data))
    } catch(e){
        console.log(e)
    }
}

export function* handleSellerProducts(action){
    try{
        const response = yield call(requestProductsBySeller, action.sellerId)
        const {data} = response
        yield put(setSellerProducts(data))
    } catch(e){
        console.log(e)
    }
}

export function* handleGetHomeProducts(action){
    try{
        const response = yield call(requestHomeProducts, action.country)
        const {data} = response
        yield put(setHomeProducts(data))
    } catch(e){
        console.log(e)
    }
}