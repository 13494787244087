import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import AppSelector from './AppSelector';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store, {persistor} from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css'
import { CustomLoader } from './components/loader';


export default function Root() {
  console.log('Root function is called')

  return (
    <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<CustomLoader/>}>
        <BrowserRouter basename={ '/' }>
          <AppSelector />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  )
}



ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
