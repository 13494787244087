import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Col, Row, Form} from 'react-bootstrap'
import { CustomLoader } from '../components/loader'
import { deleteAddress, setAddress } from '../redux/ducks/user_reducer'
import { addNewSellerAddress, changePassword, sellerMoreAddresses, updateAddress } from '../redux/sagas/requests/api_requests'

export default function MyAccount() {
    const [toggleAddAddres, setToggleAddAddres] = useState(false)
    const [newAddress, setNewAddress] = useState('')
    const [addError, setAddError] = useState('')

    const [isUpdate, setIsUpdate] = useState(false)
    const [addressId, setAddressId] = useState('')

    const userData = useSelector(state => state.user.user)
    const [addressess, setAddressess] = useState(undefined)

    const [oldpassword, setOldpassword] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [updateMsg, setUpdateMsg] = useState('')
    
    let user = undefined
    if (userData.user !== undefined){
        user = userData.user
    } else {
        user = userData.data
    }

    const fetchSellerAddresses = async () => {
        const resp = await sellerMoreAddresses(user.id)
         if (resp.data.status === 'success'){
             setAddressess(resp.data.data)
         }
    }

    useEffect(async () => {
       if (user !== undefined){
         fetchSellerAddresses()
       }
    }, [user])

    // Upload Address
    const handleUploadAddress = async () => {
        if (newAddress !== ''){
            const resp = await addNewSellerAddress(user.login_token, newAddress)
            console.log(resp.data)
            if (resp.data.status === 'success'){
                fetchSellerAddresses()
            }
        } else {
            setAddError('Please provide some address')
        }
    }

    // Update Address
    const handleUpdateAddress = async () => {
        if (newAddress !== '' && addressId !== ''){
            const resp = await updateAddress(user.login_token, newAddress, addressId)
            console.log(resp.data)
            if (resp.data.status === 'success'){
                fetchSellerAddresses()
            }
        } else {
            setAddError('Please provide some address')
        }
    }
    
    if (userData === undefined){
        return CustomLoader()
    }

    return (
        <div style={{width:'60%', margin:'auto', paddingTop:'50px', paddingBottom:'50px'}}>
            <div style={{fontSize:'25px', fontWeight:'bold'}}>My Profile</div>
            <Form>
                    <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        {/* NAME FIELD*/}
                        <div style={{textAlign:'left'}}><Form.Label>Full Name</Form.Label></div>
                        <Form.Control type="text" placeholder="Enter full name" defaultValue={user.full_name} disabled="disabled"/>
                        </Form.Group><br/>

                        {/* EMAIL FIELD */}
                        <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{textAlign:'left'}}><Form.Label>Email address</Form.Label></div>
                        <Form.Control type="email" placeholder="Enter email" defaultValue={user.email} disabled="disabled" />
                        </Form.Group><br/>

                    </Form.Row>
                    
                    <Form.Group as={Col} controlId="formGridEmail">
                        {/* PHONE NUMBER FIELD */}
                        <div style={{textAlign:'left'}}><Form.Label>Phone Number</Form.Label></div>
                        <Form.Control type="phone" placeholder="Enter phone number" defaultValue={user.mobile_number} />
                        </Form.Group>
                    <br/>

                    </Form>

                    {/* OTHER ADDRESSESS */}
                    {userAddresses(addressess)}

                    {/* ADD NEW ADDRESS */}
                    <button 
                        onClick={()=> {
                            setIsUpdate(false)
                            setToggleAddAddres(!toggleAddAddres)}}
                        className="button"
                        style={{marginTop:'10px'}}>{!toggleAddAddres ? <span>Add Address</span>:<span>Hide</span>}</button>
                    {toggleAddAddres ? 
                        <>
                        <div style={{color:'red'}}>{addError}</div>
                        <Row>
                            <Col md = "10">
                                <Form.Group controlId="formGridAddress1" style={{marginTop:'8px'}}>
                                    <Form.Control 
                                    value={newAddress}
                                    onChange={(e)=>{
                                        setAddError('')
                                        setNewAddress(e.target.value)}} placeholder="Add new address" />
                                </Form.Group>
                            </Col>
                            <Col md="1">
                            <button 
                                onClick={isUpdate? handleUpdateAddress: handleUploadAddress} 
                                className="button" style={{width:'100px'}}>
                                <span>{isUpdate?'Update':'Upload'}</span>
                            </button>
                            </Col>
                        </Row>
                        </>
                    : ''}
                    <br/>
                    <br/>
                    <hr/>

                    {/* RESET PASSWORD */}
                    <div style={{fontSize:'25px', fontWeight:'bold'}}>Reset Password</div>
                    <Form.Group controlId="formBasicPassword">
                    {/* OLD PASSWORD FIELD */}    
                    <div style={{textAlign:'left'}}><Form.Label>Old Password</Form.Label></div>
                        <Form.Control value={oldpassword} onChange={(e)=>setOldpassword(e.target.value)} type="password" placeholder="Password" />
                    </Form.Group>
                    <br/>
                    <Form.Group controlId="formBasicPassword">
                    {/* NEW PASSWORD FIELD */}    
                    <div style={{textAlign:'left'}}><Form.Label>New Password</Form.Label></div>
                        <Form.Control value={newpassword} onChange={(e)=>setNewpassword(e.target.value)} type="password" placeholder="Password" />
                    </Form.Group>
                    <br/>
                    <div style={{padding:'10px'}}>{updateMsg}</div>
                    <button 
                        onClick={async () => {
                            if (oldpassword !== '' && newpassword !== ''){
                                if (newpassword.length >= 6){
                                    const response = await changePassword(oldpassword, newpassword, user.email)
                                    if (response.data.status === 'success'){
                                        setNewpassword('')
                                        setOldpassword('')
                                        setUpdateMsg('Password updated successfully')
                                    } else {
                                        setUpdateMsg('Failed to update!')
                                    }
                                }
                            } else {
                                setUpdateMsg('Password must be 6 characters long!')
                            }
                        }}
                    className="button" style={{width:'200px'}}><span>Change Password</span></button>
        </div>
    )

    function userAddresses(addressess){
        if (addressess != undefined && addressess.length > 0){
            return (
                <>
                    <div style={{textAlign:'left'}}><h4>Addresses</h4></div>
                    {addressess.map((add, index)=>(
                        <div style={{textAlign:'left', borderRadius:'3px',border:'1px solid #ccc', marginTop:'10px', padding:'5px'}} key={index}>
                            <Row>
                                <Col md="10">
                                    {add.address}
                                </Col>
                                <Col md="2">
                                    <div 
                                    onClick={async () => {
                                        // Edit Address
                                        setNewAddress(add.address)
                                        setAddressId(add.id)
                                        setToggleAddAddres(true)
                                        setIsUpdate(true)
                                    }}
                                    style={{textAlign:'right',cursor:'pointer',color:'blue', paddingRight:'3px'}}>Edit</div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </>
            )
        } else {
            return ''
        }
    }
}



