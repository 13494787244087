import React from 'react'
import { useSelector } from 'react-redux'
import { selectdLocale } from '../langs/locale'
import { payWithPaypal } from '../redux/sagas/requests/api_requests'
import { useHistory } from 'react-router'

export default function PaymentMode(props) {
    const lang = selectdLocale(useSelector(state => state.user.locale))

    let history = useHistory()

    var amount = props.match.params.amount;
    const ids = props.match.params.ids
    console.log(ids)

    const handlePaypalPayment = async () => {
        let response = await payWithPaypal(amount, 'Test Payment Through Web', ids)
        console.log(response.data)
        const bc = new BroadcastChannel("my_spa_listener");
        window.open(response.data.redirect_url);
        bc.onmessage = function(ev) {
            console.log("Got a message from the pop-up: ", ev.data);
      };
    }
    
    return (
        <div style={{minHeight:'77vh'}}>
            <h4 style={{margin:'20px'}}>{lang.totalAmountToPay} ${amount} </h4>
            <hr/>
            {/* PAYPAL */}
            <div style={{marginTop:'20px'}}>
                <span><img src="/assets/images/payment_paypal.png" width="50" height="50"/></span>
                <span 
                    onClick={()=> handlePaypalPayment()}
                    style={{textAlign:'left', padding:'10px', cursor:'pointer', fontSize:'20px'}}>
                        {lang.payWithPaypalLabel}
                </span>
            </div>


            {/* STRIPE */}
            <div style={{marginTop:'20px'}}>
                <span><img src="/assets/images/payment_stripe.png" width="50" height="50"/></span>
                <span 
                    onClick={()=>{
                        history.push(`/paywithstripe/${amount}/${ids}`)
                    }}
                    style={{textAlign:'left', padding:'10px', cursor:'pointer', fontSize:"20px"}}>
                        {lang.payWithStripeLabel}
                </span>
            </div>
            
        </div>
    )
}
