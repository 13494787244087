import React from 'react'
import pageLogo from '../assets/images/page_logo.png'

export default function AboutUs() {
    return (
        <div style={{minHeight:'77vh', paddingTop:'30px'}}>
            <img src={pageLogo} width="150" height="150"/>
            <h2>About Us</h2>
            <div style={{width:'40%', margin:'auto'}}>
                <p style={{fontSize:'18px'}}>
                We are a company dedicated to provide both customers and stores with an easier way to process transactions online as well as to provide stores with an easier way to show their products and put them up for sale in today's market
                </p>
            </div>
            
        </div>
    )
}
