
import React, {useState, useEffect} from 'react'
import { Navbar, Nav, NavDropdown} from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setLocale, setUser } from '../../redux/ducks/user_reducer'
import { selectdLocale } from '../../langs/locale'
import splashImage from '../../assets/images/splash_image.png'

export default function Header() {
  const [width, setWidth] = useState(window.innerWidth)
  const user = useSelector(state => state.user.user)
  const [categories, setCategories] = useState(undefined)
  const cats = useSelector(state => state.category.categories)

  let lang = selectdLocale(useSelector(state => state.user.locale))

  const dispatch = useDispatch()

  useEffect(() => {
    if (cats !== undefined){
      setCategories(cats.data)
    }
  }, [cats])

  const history = useHistory()

    return (
        <>
          <Navbar style={{backgroundColor:'#000000'}} expand="lg" bg="dark" variant="dark">
            <Navbar.Brand href="#home" style={{marginTop:'5px', fontWeight:'bold', color:'white'}}>
              <img src={splashImage} 
              style={{width:'45px', height:'35px', marginLeft:'15px', marginRight:'5px', color:'white'}}/>
              PANDA SELLER
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" style={{paddingLeft:'80px'}}>
              <Nav className="mr-auto">
                <Nav.Link onClick={()=>history.push('/')}>{lang.myOrdersLabel}</Nav.Link>

                {user !== undefined ?  <Nav.Link onClick={()=>history.push('/myitems')}>{lang.myItemsLabel}</Nav.Link>:''}
               
                
                {user !== undefined ? 
                  <Nav.Link onClick={()=>history.push('/insight')}>{lang.insightLabel}</Nav.Link>
                  :''}
                {user !== undefined ? 
                  <Nav.Link onClick={()=>history.push('/earning')}>{lang.myEarningsLabel}</Nav.Link>
                  :''} 

                {user !== undefined ? 
                  <Nav.Link onClick={()=>history.push('/myaccount')}>{lang.myProfileLabel}</Nav.Link>
                  :''} 

                <Nav.Link onClick={()=>history.push('/about')}>{lang.aboutustext}</Nav.Link>

                <NavDropdown title={lang.languageLabel} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={()=>dispatch(setLocale('en'))}>{lang.englishLabel}</NavDropdown.Item>
                    <NavDropdown.Item onClick={()=>dispatch(setLocale('sp'))}>{lang.spanishLabel}</NavDropdown.Item>
                  </NavDropdown>
                {user !== undefined ?
                  <Nav.Link onClick={()=> {
                    dispatch(setUser(undefined))
                    history.push('/login')
                  }}>{lang.logoutLabel}</Nav.Link>
                :<Nav.Link onClick={()=> history.push('/login')}>{lang.loginLabel}</Nav.Link>}
                
                
              </Nav>
              
              
            </Navbar.Collapse>
            
          </Navbar>
        </>
    )
}
