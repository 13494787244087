import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { AbsoluteLoader, Loader } from '../components/loader'
import ReactStars from "react-rating-stars-component";
import { giveFeedbackToProduct } from '../redux/sagas/requests/api_requests';
import { useHistory } from 'react-router-dom';
import { selectdLocale } from '../langs/locale';


export default function WriteReview() {
    const [product, setProduct] = useState(useSelector(state => state.product.productToOpen))
    const [rating, setRating] = useState(undefined)
    const [review, setReview] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [errorText, setErrorText] = useState('')

    const history = useHistory()
    const lang = selectdLocale(useSelector(state => state.user.locale))

    const userData = useSelector(state => state.user.user)
    let user = undefined
    if (userData.user !== undefined){
        user = userData.user
    } else {
        user = userData.data
    }

    if (product === undefined){
        return Loader()
    }

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRating(newRating)
      };

    const handleRating = async () => {
        console.log('rating handle called')
        console.log(rating)
        console.log(user)
        if (user !== undefined && rating !== undefined){
            setShowLoader(true)
            const response = await giveFeedbackToProduct(product.id, user.id, rating, review)
            setShowLoader(false)
            if (response.data.status === 'success'){
                console.log('review uploaded')
                history.goBack()
            } else {
                console.log(response.data.status)
                setErrorText(lang.someErrorOccuredLabel)
            }
        } else {
            setErrorText(lang.ratingRequiredLabel)
        }
    }  

    return (
        <div style={{minHeight:'77vh'}}>
            {showLoader ? AbsoluteLoader() : ''}
            {errorText !== '' ? <div style={{padding:'10px', color:'red'}}>{errorText}</div>:''}
            <div style={{width:'200px', height:'130px', margin:'auto', paddingTop:'20px'}}>
            <img style={{height:'150px', width:'200px'}} src={'https://paolashopping.com/apis/storage/media/Products/'+product.images[0].image_url}/>
            </div>
            <br/>
            <div style={{textAlign:'center', width:'300px', margin:'auto', paddingTop:'20px'}}><h3>{product.product_name}</h3></div>
            <br/>
            <div style={{textAlign:'center', width:'300px', margin:'auto', color:'grey'}}><h4>{lang.howWasExperienceLabel}</h4></div>
            <br/>
            <div style={{width:'140px', margin:'auto', textAlign:'center'}}>
                <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                />
            </div>
            <br/>
            <div style={{width:'300px', margin:'auto', textAlign:'left', marginBottom:'7px'}}><h4>{lang.writeYourFeedbackLabel}</h4></div>
            <textarea 
                onChange={(e)=>setReview(e.target.value)}
                placeholder="Write your review" style={{width:'300px', height:'100px'}}></textarea>
            <div>
               <button onClick={handleRating} className='button' style={{width:'200px'}}>{lang.giveFeedbackLabel}</button>
            </div>    
        </div>
    )
}
