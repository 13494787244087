import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { CustomLoader } from '../components/loader'
import {Form, Col} from 'react-bootstrap'
import { emailFeedback } from '../redux/sagas/requests/api_requests'
import pageLogo from '../assets/images/page_logo.png'
import { selectdLocale } from '../../../langs/locale'

export default function ContactUs() {
    const userData = useSelector(state => state.user.user)
    const [showMessage, setShowMessage] = useState(false)
    const [textToShow, setTextToShow] = useState('')
    const [feedbackValueText, setFeedbackValueText] = useState('')
    const [message, setMessage] = useState('')

    const lang = selectdLocale(useSelector(state => state.user.locale))

    if (userData === undefined){
        return CustomLoader()
    }
    let user = undefined
    if (userData.user !== undefined){
        user = userData.user
    } else {
        user = userData.data
    }


    return (
        <div style={{minHeight:'77vh', paddingTop:'30px'}}>
            
            <img src={pageLogo} width="150" height="150"/>
            <h2>{lang.contactUsLabel}</h2>
            
            <div style={{width:'40%', margin:'auto', paddingBottom:'50px'}}>
                <p style={{fontSize:'18px'}}>
                   {lang.feedbackQueryTextLabel}
                </p>

                {showMessage ? <div style={{color: textToShow === lang.failedToSendFeedbackLabel ? 'red':'green', fontSize:'18'}}> {textToShow}</div>:''}

                <Form.Group as={Col} controlId="formGridEmail">
                        {/* NAME FIELD*/}
                        <div style={{textAlign:'left'}}><Form.Label>{lang.fullNameLabel}</Form.Label></div>
                        <Form.Control type="text" placeholder={lang.enterFullNameLabel} defaultValue={user.full_name} disabled="disabled"/>
                        </Form.Group><br/>
                    
                    <Form.Group as={Col} controlId="formGridEmail">
                        {/* PHONE NUMBER FIELD */}
                        <div style={{textAlign:'left'}}><Form.Label>{lang.phoneNoLabel}</Form.Label></div>
                        <Form.Control type="phone" placeholder={lang.enterPhoneNumberLabel} defaultValue={user.mobile_number} />
                    </Form.Group>
                    <br/>

                    <Form.Group controlId="formGridAddress1">
                        {/* Message FIELD */}
                        <div 
                        style={{textAlign:'left'}}><Form.Label>{lang.yourFeedbackLabel}</Form.Label></div>
                        <Form.Control value={feedbackValueText} onChange={(e)=>setFeedbackValueText(e.target.value)} placeholder={lang.enterYourFeedbackLabel}/>
                    </Form.Group>        
                    <br/>
                    <button 
                    onClick={async ()=>{
                        if (feedbackValueText !== ''){
                            const response = await emailFeedback(user.full_name, user.email, feedbackValueText)
                            console.log(response.data)
                            setFeedbackValueText('')
                            if (response.data.status === 'success'){
                                setTextToShow(lang.feedbackSentSuccessLabel)
                                setShowMessage(true)
                            } else {
                                setTextToShow(lang.failedToSendFeedbackLabel)
                                setShowMessage(true)
                            }
                        }
                        
                    }}
                    className="button" style={{width:'200px'}}><span>{lang.submitLabel}</span></button>
                    
            </div>
        </div>
    )
}
