import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div style={{minHeight:'77vh', paddingTop:'30px'}}>
            <h2>Privacy Policy</h2>
            <div style={{width:'40%', margin:'auto'}}>
                <p style={{fontSize:'18px'}}>
                We are a company dedicated to provide both customers and stores with an easier way to process transactions online as well as to provide stores with an easier way to show their products and put them up for sale in today's market
                </p>
            </div>
            
        </div>
    )
}
