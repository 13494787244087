import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Row, Col} from 'react-bootstrap'
import { Loader } from '../components/loader'
import Seller from '../helper/Seller'
import { requestOrdersInsight, requestRecentTransactions } from '../redux/sagas/requests/api_requests'
import dateFormat from 'dateformat';
import { productRow } from '../utils'
import { selectdLocale } from '../langs/locale'

export default function Earnings() {
    const [recentOrders, setRecentOrders] = useState(undefined)
    const [insight, setInsight] = useState(undefined)
    const lang = selectdLocale(useSelector(state => state.user.locale))

    const {seller} = Seller()

    // RECENT TRANSACTIONS
    useEffect(async() => {
        if (seller !== undefined){
            const response = await requestRecentTransactions(seller.id)
            console.log(response.data)
            if (response.data.status === 'success'){
                let orders = response.data.data
                setRecentOrders(orders)
            }

            const resp = await requestOrdersInsight(seller.id)
            if (resp.data.status === 'success'){
                setInsight(resp.data.data)
            }
        }
    }, [seller])

    if (recentOrders === undefined){
        return Loader()
    }

    const w = window.innerWidth
    return (
        <div className="main-content">
        <div style={{minHeight:'73vh', width: w > 500 ? '60%':'90%', margin:'auto'}}>
            {/* Revenue Earning */}
            {revenueEarningsRow()}

            {/* Recent Transactions */}
            {recentOrders !== undefined ? 
                <div>
                    <h3 style={{textAlign:'left', fontWeight:'bold', paddingTop:'40px'}}>{lang.recentTransactionsLabel}</h3>
                    {recentOrders.length === 0 ? <h5 style={{textAlign:'left', marginTop:'20px'}}>No recent transactions.</h5>:''}
                    {
                        recentOrders.map((order, ind)=>(
                            <>
                                {recentTransactions(order, ind)}
                            </>
                        ))
                    }
                </div>
            :''}
            
        </div>
        </div>
    )

    function revenueEarningsRow(){
        return (
            <div style={{display:'flex', padding:'5px', border:'1px solid white', borderRadius:'10px', backgroundColor:'white', boxShadow:'0px 0px 4px grey'}}>
                <div style={{padding:'10px', textAlign:'center', width:'50%', borderRight:'solid 1px #ccc'}}>
                    <h5><span style={{color:'grey', marginBottom:'4px', lineHeight:'1.7'}}>{lang.revenueLabel}</span><br/> ${insight !== undefined ? insight.total_revenue.toFixed(2) :'Loading...'}</h5>
                </div>
                <div style={{padding:'10px', textAlign:'center', width:'50%'}}>
                    <h5><span style={{color:'grey', marginBottom:'4px', lineHeight:'1.7'}}>{lang.earningsLabel}</span><br/> ${insight !== undefined ? insight.balance.toFixed(2) :'Loading...'}</h5>
                </div>
            </div>
        )
    }

    function recentTransactions(o, index){
        return (
            <>
                {orderCard(o, index)}
            </>
        )
    }

    function orderCard(o, index){
        return (
            <div key={index} className="order-card" style={{width:'100%', backgroundColor:'white', border:'1px solid white', boxShadow:'0px 0px 7px grey', marginBottom:'0px'}}>
                <div  style={{borderRadius:'5px 5px 0px 0px', borderBottom:'1px solid #ccc'}}>
                        <Row>
                            <Col md="8">
                                <div style={{paddingTop:'10px', paddingLeft:'10px', textAlign:'left'}}><span style={{fontWeight:'bold'}}>{lang.orderIdLabel}</span>: <span style={{color:'grey'}}>{o.id}</span> </div>
                                <div style={{paddingLeft:'10px', paddingBottom:'8px', textAlign:'left'}}><span style={{fontWeight:'bold'}}>{lang.orderedOnLabel}</span>: <span style={{color:'grey'}}>{dateFormat(o.created_at, "mmmm dS, yyyy")}</span></div>
                            </Col>
                            <Col md="4">
                                <div style={{padding:'10px', textAlign:'right', color:'green', fontSize:'17px', fontWeight:'bold'}}>{lang.paymentLabel}: ${o.total_amount}</div>
                            </Col>
                        </Row>
                        </div>
                        {o.orderdetail.map((od, odi)=>{
                            const q = od.quantity
                            const r = od.is_reviewed
                            return (
                                <div key={odi}>
                                    {od.product_detail.map((p, pi)=>(
                                        <>
                                            {productRow(p, q)}
                                        </>
                                    ))}
                                </div>
                            );
                        })}
                        {/* {paymentRow(o.total_amount.toFixed(2), o.order_status)} */}
            </div>
        )
    }
}
