import React from 'react'
import {BiErrorAlt} from 'react-icons/bi'
import { selectdLocale } from '../langs/locale'
import { useSelector } from 'react-redux'

export default function PaymentFailed() {
    const lang = selectdLocale(useSelector(state => state.user.locale))

    return (
        <div style={{minHeight:'77vh', padding:'100px'}}>
            <BiErrorAlt style={{color:'red', width:'100px', height:'100px'}}/>
            <br/>
            <h4 style={{color:'red'}}>{lang.failedPaymentLabel}</h4>
        </div>
    )
}
