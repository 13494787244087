import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Product } from '../components/homepage/product'
import { CustomLoader } from '../components/loader'
import {Row} from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { setProdcutToOpen } from '../redux/ducks/products_reducer'
import { removeWishItem } from '../redux/ducks/cart_reducer'
import {FaHeart} from 'react-icons/fa'
import { selectdLocale } from '../langs/locale'


export default function WishtList() {
    const wishItems = useSelector(state => state.cartItem.wishItems)

    const dispatch = useDispatch();
    let history = useHistory()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    if (wishItems === undefined){
        return CustomLoader()
    }
    
    function handleClick(id, prod) {
        dispatch(setProdcutToOpen(prod))
        history.push(`/product/${id}`);
    }

    if (wishItems.length === 0) {
        return (
            <div style={{minHeight:'62vh',marginTop:'100px'}}><h4>{lang.noProductAddedFavoriteLabel}</h4></div>
        )
    }

    return (
        <div style={{minHeight:'77vh'}}>
            <Row>
                { 
                    wishItems.map((p, i)=>(
                        <div key={i} style={{position:'relative',height:'420px', width:'290px', marginLeft:'15px'}}>
                                
                        {Product(p, handleClick)}
                        <div 
                            onClick={() => dispatch(removeWishItem(p.id))}
                            style={{position:'absolute', top:'25px', right:'5px', color:'red', zIndex:'10'}}><FaHeart style={{width:'30px', height:'30px'}}/></div>
                    </div>))
                }
            </Row>
        </div>
    )
}

