import {ScaleLoader} from 'react-spinners'

export function Loader(){
    return (
        <div style={{width:'100%', height:'77vh', padding:'100px'}}>
            <ScaleLoader/>
        </div>
    )
}

export function CustomLoader(){
    return (
        <div style={{position: 'absolute', width:'100%', height:'77vh', paddingTop:'20%', zIndex:'10'}}>
            <ScaleLoader/>
        </div>
    )
}

