import React from 'react'
import { useSelector } from 'react-redux'
import { selectdLocale } from '../langs/locale'

export default function AboutUs() {
    const lang = selectdLocale(useSelector(state => state.user.locale))
    return (
        <div style={{minHeight:'77vh', paddingTop:'30px'}}>
            <img src="/assets/images/page_logo.png" width="150" height="150"/>
            <h2>{lang.aboutustext}</h2>
            <div style={{width:'40%', margin:'auto'}}>
                <p style={{fontSize:'18px'}}>
                We are a company dedicated to provide both customers and stores with an easier way to process transactions online as well as to provide stores with an easier way to show their products and put them up for sale in today's market
                </p>
            </div>
            
        </div>
    )
}
