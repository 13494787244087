export default class OrderToPlace {
    constructor(customerId, totalAmount, deliveryCharge, deliveryAddress, pickAddress, items){
        this.customer_id = customerId
        this.total_amount = totalAmount
        this.delivery_charge = deliveryCharge
        this.delivery_address = deliveryAddress
        this.pick_address = pickAddress
        this.items = items
    }
}
