import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const SET_USER_COUNTRY = 'setusercountry'

const SET_USER = 'setuser'

const SET_ADDRESS = 'setaddress'

const SET_LOCALE = 'locale'

const DELETE_ADDRESS = 'deleteaddress'

const SET_USER_TYPE = 'setusertype'

export const setCountry = (country)=>({
    type: SET_USER_COUNTRY,
    country
})

export const setUser = (user) => ({
    type: SET_USER,
    user
})

export const setUserType = (userType) => ({
    type: SET_USER_TYPE,
    userType
})

export const setAddress = (address) => ({
    type: SET_ADDRESS,
    address
})

export const setLocale = (locale) => ({
    type: SET_LOCALE,
    locale
})

export const deleteAddress = (add) => ({
    type: DELETE_ADDRESS,
    add
})

const initialState = {
    country: undefined,
    user: undefined,
    addressess: [],
    locale: 'en',
    userType: undefined
}

const userReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_USER_COUNTRY:
            const country = action.country
            return {...state, country} 
        case SET_USER:
            const user = action.user  
            return {...state, user}  
        case SET_USER_TYPE:
            const userType = action.userType
            return {...state, userType}    
        case SET_ADDRESS:
            const address = action.address    
            return {...state, addressess: [...state.addressess, address]} 
        case DELETE_ADDRESS:
            const addressToDelete = action.add  
            const filteredAddresses = state.addressess.filter(add => add !== addressToDelete)
            return {...state, addressess: filteredAddresses}  
        case SET_LOCALE:
            const locale = action.locale  
            return {...state, locale}  
        default:
            return state;    
    }
}

const persistConfig = {
    keyPrefix: "panda-",
    key: "user",
    storage
}

export default persistReducer( persistConfig, userReducer );