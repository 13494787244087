import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Row, Col} from 'react-bootstrap'
import { Loader } from '../components/loader'
import Seller from '../helper/Seller'
import { requestOrdersInsight, requestRecentTransactions, requestTopSellingProducts } from '../redux/sagas/requests/api_requests'
import dateFormat from 'dateformat';
import { productRow } from '../utils'
import { PRODUCT_HOST } from '../constants'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { selectdLocale } from '../langs/locale'

export default function Insight() {
    const [topSelling, setTopSelling] = useState(undefined)
    const [insight, setInsight] = useState(undefined)
    const [chartData, setChartData] = useState([])

    const {seller} = Seller()
    const lang = selectdLocale(useSelector(state => state.user.locale))

    // RECENT TRANSACTIONS
    useEffect(async() => {
        if (seller !== undefined){
            const response = await requestTopSellingProducts(seller.id)
            if (response.data.status === 'success'){
                setTopSelling(response.data.data)
            }

            const resp = await requestOrdersInsight(seller.id)
            if (resp.data.status === 'success'){
                setInsight(resp.data.data)
            }
        }
    }, [seller])

    // CHART DATA
    useEffect(() => {
       if (insight !== undefined){
           const datesNumbers = insight.orders_count_placed_per_week
           let items = []
           
           for (const key in datesNumbers) {
                if (datesNumbers.hasOwnProperty(key)) {
                    //console.log(`${key}: ${datesNumbers[key]}`);
                    const date = key.replace("\n", "~")
                    let chartItem = {
                        name: 'Date Label',
                        uv: 3
                       }
                    chartItem.name = date
                    chartItem.uv = datesNumbers[key]
                    items.push(chartItem)
                }
            }
            console.log(items)
            setChartData(items)   
       }
    }, [insight])

    if (topSelling === undefined){
        return Loader()
    }

    const w = window.innerWidth
    return (
        <div className="main-content">
        <div style={{minHeight:'73vh', width: w > 500 ? '60%':'90%', margin:'auto'}}>
            {/* Revenue Earning */}
            {revenueEarningsRow()}

            {/* Statistics */}
            <br/>  
            <h4>{lang.orderStatisticsLabel}</h4>
            <br/>
            <div style={{width:'80%', height:'300px',backgroundColor:'white', margin:'auto'}}>{chart()}</div>

            {/* Recent Transactions */}
            {topSelling !== undefined ? 
                <div>
                    <h3 style={{textAlign:'left', fontWeight:'bold', paddingTop:'40px'}}>{lang.totSellingProductsLabel}</h3>
                    {
                        topSelling.map((p, ind)=>(
                            <>
                                {soldProductRow(p[0], ind)}
                            </>
                        ))
                    }
                </div>
            :''}
            
        </div>
        </div>
    )

    function revenueEarningsRow(){
        return (
            <div style={{display:'flex', padding:'5px', border:'1px solid white', borderRadius:'10px', backgroundColor:'white', boxShadow:'0px 0px 4px grey'}}>
                <div style={{padding:'15px', textAlign:'center', width:'33.3%', borderRight:'solid 1px #ccc'}}>
                    <h5><span style={{lineHeight:'1.7', color:'grey', marginBottom:'4px'}}>{lang.ordersLabel}</span><br/> {insight !== undefined ? insight.total_orders :'Loading...'}</h5>
                </div>
                <div style={{padding:'15px', textAlign:'center', width:'33.3%', borderRight:'solid 1px #ccc'}}>
                    <h5><span style={{lineHeight:'1.7', color:'grey', marginBottom:'4px'}}>{lang.revenueLabel}</span><br/> {insight !== undefined ? insight.total_revenue.toFixed(2) :'Loading...'}$</h5>
                </div>
                <div style={{padding:'15px', textAlign:'center', width:'33.3%'}}>
                    <h5><span style={{lineHeight:'1.7', color:'grey', marginBottom:'4px'}}>{lang.pendingLabel}</span><br/> {insight !== undefined ? insight.orders_not_delivered_yet :'Loading...'}</h5>
                </div>
            </div>
        )
    }

    function soldProductRow(product, ind){
        if (product !== undefined){
            return (
                <div key={ind} className="order-card" style={{width:'100%'}}>
                    <Row className="align-items-center" style={{padding:'5px'}}>
                        <Col md="2"><img src={PRODUCT_HOST+product.images[0].image_url} style={{width:'80px', height:'80px'}}/></Col>
                        <Col md="2"><div style={{fontWeight:'bold'}}>{product.product_name}</div></Col>
                        <Col md="4"><div>{lang.categoryLabel}: <span style={{color:'green'}}>{product.category.category_name}</span></div></Col>
                       
                        <Col md="3"><div>{lang.noOfSoldsLabel}: <span style={{color:'green'}}>{product.totalSale}</span></div></Col>
                    </Row>
                </div>
            )
        } else {
            return (<div key={ind}></div>)
        }
        
    }

    function chart(){
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                width={500}
                height={400}
                data={chartData}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

}
