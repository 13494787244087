
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Product } from '../components/homepage/product';
import { Loader } from '../components/loader';
import { requestCategoriesAgainstCategory } from '../redux/sagas/requests/products_req';
import { setProdcutToOpen } from '../redux/ducks/products_reducer';
import { useHistory } from 'react-router-dom';
import { getProductsByCategory } from '../redux/sagas/requests/api_requests';
import { useSelector } from 'react-redux';
import { selectdLocale } from '../langs/locale';

export default function CategoryProducts(props) {
    const [catProducts, setcatProducts] = useState(undefined)
    const [subcats, setSubcats] = useState(undefined)
    const [selectedCat, setSelectedCat] = useState('')
    const dispatch = useDispatch()
    let history = useHistory()
    const [isLoaded, setIsLoaded] = useState(false)

    const lang = selectdLocale(useSelector(state => state.user.locale))

    const country = useSelector(state => state.user.country)

    const catId = props.match.params.id

    useEffect(async () => {
        window.scrollTo(0, 0)
        let response = await requestCategoriesAgainstCategory(catId)
        setSubcats(response.data.data)
        setIsLoaded(true)
        if (response.data.data !== undefined && response.data.data.length > 0) {
            setSelectedCat(response.data.data[0].category_name)
        }
    }, [])

    useEffect(() => {
        if (subcats !== undefined && subcats.length !== 0 && country !== undefined) {
            fetchSubcatProducts(subcats[0].id, 20, country)
        }
    }, [subcats])

    const fetchSubcatProducts = async (id, pageNo, country) => {
        console.log(id)
        const response = await getProductsByCategory(id, pageNo, country)
        setcatProducts(response.data.data)
        console.log(response.data.data)
    }

    if (!isLoaded && subcats === undefined) {
        return Loader()
    }

    if (isLoaded && subcats.length === 0) {
        return <div style={{ height: '80vh', padding: '100px', fontSize: '22px' }}>No subcategory available under this category.</div>
    }

    if (catProducts === undefined) {
        return Loader()
    }


    return (
        <Row style={{ height: '80vh' }}>

            <Col md="3">
                <div style={{ minHeight: '70vh', borderRight: '1px solid #ccc', marginTop: '10px' }}>
                    {
                        subcats.map((cat, index) => (
                            <div

                                style={{ cursor: 'pointer', textAlign: 'left', paddingLeft: '20px' }}
                                onClick={() => {
                                    setcatProducts(undefined)
                                    setSelectedCat(cat.category_name)
                                    fetchSubcatProducts(cat.id, 2, country)
                                }}

                                key={index}>
                                <span className="side-cat">{cat.category_name}</span>
                            </div>
                        ))
                    }
                </div>
            </Col>
            <Col md="9">
                <div style={{ textAlign: 'left', marginTop: '20px', marginBottom: '10px', marginLeft: '18px' }}><h4>{selectedCat}</h4></div>
                <Row style={{ margin: '0px' }}>
                    {catProducts !== undefined && catProducts.length > 0 ? catProducts.map((p, i) => (
                        <Col md="4" style={{ minWidth: '280px', maxWidth: '320px' }}>
                            {Product(p, () => {
                                dispatch(setProdcutToOpen(p))
                                history.replace('/product/' + p.id)
                            })}
                        </Col>
                    )) : (catProducts !== undefined && catProducts.length === 0 ?
                        <div style={{ paddingTop: '100px' }}><h4>{lang.noProductsAvailabelLabel}</h4></div>
                        : (isLoaded && subcats === undefined ? 'No subcategory available' : Loader()))}
                </Row>
            </Col>
        </Row>

    )
}


