import React, {useState, useEffect} from 'react'
import { Loader } from '../components/loader'
import { PRODUCT_HOST } from '../constants'
import { requestOrdersAgainstUser } from '../redux/sagas/requests/api_requests'
import { Row, Col } from 'react-bootstrap'
import {IoCheckmarkDone} from 'react-icons/io5'
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux'
import { selectdLocale } from '../langs/locale'
import { useDispatch } from 'react-redux'
import { setProdcutToOpen } from '../redux/ducks/products_reducer'
import { useHistory } from 'react-router'
import { languages } from 'country-data'

export default function MyOrders() {
    const [orders, setOrders] = useState(undefined)
    const userData = useSelector(state => state.user.user)
    const lang = selectdLocale(useSelector(state => state.user.locale))
    const dispatch = useDispatch()
    const history = useHistory()

    let user = undefined
    if (userData.user !== undefined){
        user = userData.user
    } else {
        user = userData.data
    }

    useEffect(async() => {
        if (user !== undefined){
            const response = await requestOrdersAgainstUser(user.id)
            setOrders(response.data.data)
            console.log(response.data.data)
        }
    }, [])

    if (orders === undefined){
        return Loader()
    }

    if (orders.length === 0){
        return (<div style={{minHeight:'77vh', paddingTop:'100px'}}>
            <h4>{lang.have0oredersLabel}</h4>
        </div>)
    }

    return (
        <div style={{minHeight:'77vh'}}>
            {orders.map((o, i)=>{
                const orderStatus = o.order_status;
                return (
                    <div key={i} className="order-card">
                        <div  style={{backgroundColor:'#eee', borderRadius:'5px 5px 0px 0px', borderBottom:'1px solid grey'}}>
                        <Row>
                            <Col md="8">
                                <div style={{padding:'10px', textAlign:'left', fontWeight:'bold'}}>{lang.orderIdLabel}: <span style={{color:'orange', fontWeight:'bold'}}>{o.id}</span> </div>
                            </Col>
                            <Col md="4">
                                <div style={{padding:'10px', textAlign:'right'}}>{lang.orderedOnLabel}: {dateFormat(o.created_at, "mmmm dS, yyyy")}</div>
                            </Col>
                        </Row>
                        </div>
                        {o.orderdetail.map((od, odi)=>{
                            const q = od.quantity
                            return (
                                <div key={odi}>
                                    {od.product_detail.map((p, pi)=>(
                                        <>
                                            {productRow(p, q, orderStatus, user, history, dispatch, lang)}
                                        </>
                                    ))}
                                </div>
                            );
                        })}
                        {paymentRow(o.total_amount.toFixed(2), orderStatus, lang)}
                        
                        {(orderStatus !== 'delivered' && o.notes === '') ? orderStatusRow(orderStatus, lang):''}
                        
                        {(o.notes !== '' && o.order_status !== 'delivered') ? pickupLocation(o.notes, lang):''}
                    </div>
                )
                
            })}
        </div>
    )
}

function productRow(product, quantity, status, user, history, dispatch, lang){
    return (
        <>
            <Row className="align-items-center" style={{padding:'5px'}}>
                <Col md="2"><img src={PRODUCT_HOST+product.images[0].image_url} style={{width:'80px', height:'80px'}}/></Col>
                <Col md="2"><div style={{fontWeight:'bold'}}>{product.product_name}</div></Col>
                <Col md="2"><div>{lang.priceLabel}: <span style={{color:'green'}}>${product.price}</span></div></Col>
               
                <Col md="2"><div>{lang.deliveryFeeLabel}: <span style={{color:'green'}}>${product.delivery_charges}</span></div></Col>
                <Col md="2"><div>{lang.quantityLabel}: {quantity}</div></Col>
                {(!isReviewAdded(product, user) && status === 'delivered') ? <Col md="2">
                    <div 
                    onClick={()=>{
                        dispatch(setProdcutToOpen(product))
                        history.push('/review')
                    }}

                    style={{cursor:'pointer',color:'green', fontWeight:'bold'}}>{lang.writeReviewLabel}</div></Col>:''}
            </Row>
        </>
    )
}


function isReviewAdded(product, user) {
    var addedReview = false
    var customerId = user.id
    var reviews = product.reviews
    reviews.forEach(review => {
        if (review.customer_id === customerId){
            addedReview = true
        }
    });
    console.log(reviews)
    return addedReview;
  }

function paymentRow(totalPayment, status, lang){
    return (
        <div style={{backgroundColor:'#eee', display:'flex', borderRadius:'0px 0px 5px 5px'}}>
            <div style={{padding:'10px', textAlign:'left', width:'50%'}}>
                <div style={{fontWeight:'bold'}}>{lang.orderStatusLabel}</div>
                <div style={{fontWeight:'bold', color:'green'}}>{status}</div>
            </div>
            <div style={{padding:'10px', textAlign:'right', width:'50%'}}>
                <div style={{fontWeight:'bold'}}>{lang.paymentLabel}</div>
                <div style={{fontWeight:'bold', color:'green'}}>${totalPayment}</div>
            </div>
        </div>
        
    )
}

function orderStatusRow(status, lang){
    if (status == 'placed'){
        return (
            <Row style={{marginTop:'15px', marginBottom:'15px'}}>
                {statusCircle('green', 'white', 'Placed')}
                {statusCircle('#eee', 'black','Dispatched')}
                {statusCircle('#eee', 'black','Delivered')}
            </Row>
        )
    } else if (status == 'dispatched'){
        return (
            <Row style={{marginTop:'15px', marginBottom:'15px'}}>
                {statusCircle('green', 'white', 'Placed')}
                {statusCircle('green', 'white','Dispatched')}
                {statusCircle('#eee', 'black','Delivered')}
            </Row>
        )
    } else {
        return (
            <Row style={{marginTop:'15px', marginBottom:'15px'}}>
                {statusCircle('green', 'white', 'Placed')}
                {statusCircle('green', 'white','Dispatched')}
                {statusCircle('green', 'white','Delivered')}
            </Row>
        )
    }
}

function statusCircle(color, textColor, title){
    return (
        <Col md="4"><div className="status-circle" style={{margin:'auto', backgroundColor:color}}>
                <div style={{position:'relative', top:'20px'}}>
                  <div><IoCheckmarkDone style={{fontSize:'25px', color:textColor}}/></div>
                  <div style={{color:textColor}}>{title}</div>
               </div>
        </div></Col>
    )
}

function pickupLocation(address, lang){
    return (
        <div style={{padding:'10px'}}>
        <h4>{lang.pickupLocationLabel}:</h4>
        <div>{address}</div>
        </div>
    )
}
