import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Col, Row, Form} from 'react-bootstrap'
import { CustomLoader } from '../components/loader'
import { deleteAddress, setAddress } from '../redux/ducks/user_reducer'
import { changePassword } from '../redux/sagas/requests/api_requests'
import { selectdLocale } from '../langs/locale'

export default function MyAccount() {
    const [toggleAddAddres, setToggleAddAddres] = useState(false)
    const [newAddress, setNewAddress] = useState('')
    const [addError, setAddError] = useState('')
    const userData = useSelector(state => state.user.user)
    const country = useSelector(state => state.user.country)
    const addressess = useSelector(state => state.user.addressess)
    const dispatch = useDispatch()
    const [oldpassword, setOldpassword] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [updateMsg, setUpdateMsg] = useState('')

    const lang = selectdLocale(useSelector(state => state.user.locale))

    if (userData === undefined){
        return CustomLoader()
    }
    let user = undefined
    if (userData.user !== undefined){
        user = userData.user
    } else {
        user = userData.data
    }
    

    return (
        <div style={{width:'60%', margin:'auto', paddingTop:'50px', paddingBottom:'50px'}}>
            <div style={{fontSize:'25px', fontWeight:'bold'}}>{lang.myProfileLabel}</div>
            <Form>
                    <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        {/* NAME FIELD*/}
                        <div style={{textAlign:'left'}}><Form.Label>{lang.fullNameLabel}</Form.Label></div>
                        <Form.Control type="text" placeholder="Enter full name" defaultValue={user.full_name} disabled="disabled"/>
                        </Form.Group><br/>

                        {/* EMAIL FIELD */}
                        <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{textAlign:'left'}}><Form.Label>{lang.emailAddressLabel}</Form.Label></div>
                        <Form.Control type="email" placeholder="Enter email" defaultValue={user.email} disabled="disabled" />
                        </Form.Group><br/>

                    </Form.Row>
                    
                    <Form.Group as={Col} controlId="formGridEmail">
                        {/* PHONE NUMBER FIELD */}
                        <div style={{textAlign:'left'}}><Form.Label>{lang.phoneNumberLabel}</Form.Label></div>
                        <Form.Control type="phone" placeholder="Enter phone number" defaultValue={user.mobile_number} />
                        </Form.Group>
                    <br/>

                    <Form.Group controlId="formGridAddress1">
                        {/* ADDRESS FIELD */}
                        <div style={{textAlign:'left'}}><Form.Label>{lang.addressLabel}</Form.Label></div>
                        <Form.Control placeholder="1234 Main St" defaultValue={user.address} />
                    </Form.Group>
                    <br/>
                    </Form>

                    {/* OTHER ADDRESSESS */}
                    {userAddresses(addressess, dispatch, lang)}

                    {/* ADD NEW ADDRESS */}
                    <button 
                        onClick={()=> setToggleAddAddres(!toggleAddAddres)}
                        className="button"
                        style={{marginTop:'10px'}}>{!toggleAddAddres ? <span>{lang.addAddressLabel}</span>:<span>{lang.hideLabel}</span>}</button>
                    {toggleAddAddres ? 
                        <>
                        <div style={{color:'red'}}>{addError}</div>
                        <Row>
                            <Col md = "10">
                                <Form.Group controlId="formGridAddress1" style={{marginTop:'8px'}}>
                                    <Form.Control 
                                    value={newAddress}
                                    onChange={(e)=>{
                                        setAddError('')
                                        setNewAddress(e.target.value)}} placeholder="Add new address" />
                                </Form.Group>
                            </Col>
                            <Col md="1">
                            <button onClick={
                                ()=>{newAddress !== ''? 
                                    addNewAddress(dispatch, newAddress, setNewAddress)
                                : setAddError('Address not added')}
                            } className="button" style={{width:'100px'}}><span>{lang.addLabel}</span></button>
                            </Col>
                        </Row>
                        </>
                    : ''}
                    <br/>
                    <br/>
                    <hr/>

                    {/* RESET PASSWORD */}
                    <div style={{fontSize:'25px', fontWeight:'bold'}}>{lang.resetPasswordLabel}</div>
                    <Form.Group controlId="formBasicPassword">
                    {/* OLD PASSWORD FIELD */}    
                    <div style={{textAlign:'left'}}><Form.Label>{lang.oldPasswordLabel}</Form.Label></div>
                        <Form.Control value={oldpassword} onChange={(e)=>setOldpassword(e.target.value)} type="password" placeholder="Password" />
                    </Form.Group>
                    <br/>
                    <Form.Group controlId="formBasicPassword">
                    {/* NEW PASSWORD FIELD */}    
                    <div style={{textAlign:'left'}}><Form.Label>{lang.newPasswordLabel}</Form.Label></div>
                        <Form.Control value={newpassword} onChange={(e)=>setNewpassword(e.target.value)} type="password" placeholder="Password" />
                    </Form.Group>
                    <br/>
                    <div style={{padding:'10px'}}>{updateMsg}</div>
                    <button 
                        onClick={async () => {
                            if (oldpassword !== '' && newpassword !== ''){
                                if (newpassword.length >= 6){
                                    const response = await changePassword(oldpassword, newpassword, user.email)
                                    if (response.data.status === 'success'){
                                        setNewpassword('')
                                        setOldpassword('')
                                        setUpdateMsg(lang.passwordUpdatedSuccessLabel)
                                    } else {
                                        setUpdateMsg(lang.failedToUpdatePasswordLabel)
                                    }
                                }
                            } else {
                                setUpdateMsg(lang.mustBeSixCharactersLabel)
                            }
                        }}
                    className="button" style={{width:'200px'}}><span>{lang.changePasswordLabel}</span></button>
        </div>
    )
}

function userAddresses(addressess, dispatch, lang){
    if (addressess != undefined && addressess.length > 0){
        return (
            <>
                <div style={{textAlign:'left'}}><h4>{lang.otherAddressesLabel}</h4></div>
                {addressess.map((add, index)=>(
                    <div style={{textAlign:'left', borderRadius:'3px',border:'1px solid #ccc', marginTop:'10px', padding:'5px'}} key={index}>
                        <Row>
                            <Col md="10">
                                {add}
                            </Col>
                            <Col md="2">
                                <div onClick={()=> dispatch(deleteAddress(add))} style={{textAlign:'right',cursor:'pointer',color:'red', paddingRight:'3px'}}>Delete</div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </>
        )
    } else {
        return ''
    }
}

function addNewAddress(dispatch, address, setNewAddress){
    setNewAddress('')
    dispatch(setAddress(address))
}
