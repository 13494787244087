import axios from "axios"

const HOST_URL = 'https://paolashopping.com'

// LOGIN USER
export function loginUser(email, password) {
    const params = {email: email, password: password}
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis/login`
    })
}

// SOCIAL LOGIN
export function socialLogin(socialId) {
    const params = {social_id: socialId}
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis/sociallogin`
    })
}

// REGISTER USER
export function registerUser(name, email, password, country, mobileNo, address, socialId) {
    const params = {name: name, email: email, password: password, country: country, mobile_number:mobileNo, user_type:"Customer", social_id:socialId, description:"", address:address, latitude: 32.1113, longitude:44.3232}
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis//user/create`
    })
}

// FORGOT PASSWORD
export function forgotPassword(email) {
    const params = {email: email}
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis/user/forgotpassword`
    })
}

// UPDATE PHONE NUMBER
export function updatePhoneNo(token, number) {
    const params = {token: token, mobile_number: number}
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis/user/updatephoneno`
    })
}

// CHANGE PASSWORD
export function changePassword(oldpassword, newpassword, email) {
    const params = {
        password: oldpassword,
        new_password: newpassword,
        email: email
      }
    return axios.request({
        method:'post',
        params: params,
        url: `${HOST_URL}/apis/user/changePassword`
    })
}

// SUB CATEGORIES AGAINST CATEGORY
export function requestCategoriesAgainstCategory(categoryId){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/categories/getAllSubCategories/${categoryId}`
    })
}

// PRODUCTS AGAINST CATEGORY
export function getProductsByCategory(id, pageNo, country){
    const params = {category_id:id, pageNo: pageNo, country: country}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/products/getProducts?category_id=${id}&page=${pageNo}&country=${country}`
    })
}

// PRODUCT BY ID
export function getProductByID(id){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/getById/${id}`
    })
}

// PRODUCT REVIEWS
export function productReviews(id){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/getReviews/${id}`
    })
}


// GET ORDERS
export function requestOrdersAgainstUser(userId){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/order/get/${userId}`
    })
}

// GET SELLER ADDRESSES
export function getSellerAddresses(id){
    const params = {id: id}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/product/selleraddresses`
    })
}

// PRODUCT FEEDBACK 
export function giveFeedbackToProduct(productId, customerId, rating, review){
    const params = {product_id: productId, customer_id:customerId, rating:rating, review:review}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/review/create`
    })
}

// EMAIL FEEDBACK
export function emailFeedback(name, email, message){
    const params = {name: name, email:email, message:message}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/user/feedback`
    })
}

// PLACE ORDERS 
export function placeOrders(orders){
    const params = {orders: JSON.stringify(orders)}
    console.log(JSON.stringify(orders))
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/order/ordersToPlace`
    })
}

// PAYPAL PAYMENT
export function payWithPaypal(amount, description, ids){
    let idsString = ''
    let idsArr = ids.split(",")
    idsArr.forEach(id => {
        if (idsString === ''){
            idsString = id
        } else {
            idsString += ';'+id
        }
    });
    const params = {amount: amount, currency: 'USD', description: description, ids: idsString, isweb:1}
    console.log(params)
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/paynow/withpaypal`
    })
}

// STRIPE PAYMENT
export function payWithStripe(cardNo, expiryMonth, expiryYear, cvvNo, amount, description, ids){
    const params = {card_no: cardNo, ccExpiryMonth: expiryMonth, ccExpiryYear: expiryYear, cvvNumber:cvvNo, amount:amount, currency:'USD', description: description, ids:ids}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/payment/stripe`
    })
}


// OFFERS
export function offerProducts(shoppingCountry){
    return axios.request({
        method: "get",
        url: `${HOST_URL}/apis/products/offers?country=${shoppingCountry}`
    })
}

// SEARCH PRODUCTS
export function searchProducts(keyword, country){
    const params = {keyword: keyword, country: country}
    return axios.request({
        method: "post",
        params: params,
        url: `${HOST_URL}/apis/products/searchProducts`
    })
}
