import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Form, Row} from 'react-bootstrap'
import { searchProducts } from '../redux/sagas/requests/api_requests'
import { CustomLoader } from '../components/loader'
import CountrySelect from 'react-bootstrap-country-select'
import { Product } from '../components/homepage/product'
import { setCountry } from '../redux/ducks/user_reducer'
import { setProdcutToOpen } from '../redux/ducks/products_reducer'
import { useHistory } from 'react-router'
import search from '../helper/search_helper'
import { selectdLocale } from '../langs/locale'

export default function SearchPage(props) {
    const [searchedProducts, setSearchedProducts] = useState(undefined)
    const [ value, setValue ] = useState(null)
    const [keyword, setKeyword] = useState('')
    
    const searchParam = props.match.params.keyword
    const lang = selectdLocale(useSelector(state => state.user.locale))

    useEffect(() => {
        if (searchParam !== undefined){
            let newKeyword = searchParam.replace('+', ' ')
            setKeyword(newKeyword)
        }
    }, [searchParam])
    

    const country = useSelector(state => state.user.country)

    const dispatch = useDispatch()
    let history = useHistory()

    useEffect(async() => {
        setSearchedProducts(undefined)
        if (country !== undefined && keyword !== ''){
            console.log(`key: ${keyword} country: ${country}`)
            const response = await searchProducts(keyword, country)
            console.log(response.data)
            setSearchedProducts(response.data.data)
        }
    }, [keyword])

    if (country === undefined || country === ''){
        return (
          <div style={{margin:'200px', minHeight:'20vh'}}>
            <div style={{textAlign:'center'}}><Form.Label>{lang.selectCountryLabel}</Form.Label></div>
                     <CountrySelect
                          value={value}
                          onChange={(value)=>dispatch(setCountry(value.name))}
                      />
          </div>
        )
      }

      if (searchedProducts === undefined){
          return CustomLoader()
      }

      if (searchedProducts !== undefined && searchedProducts.length === 0){
          return (<div style={{paddingTop:'100px', minHeight:'77vh'}}>
              <h4>{lang.noProductsAvailabelLabel}</h4>
          </div>)
      }

      function handleClick(id, prod) {
            dispatch(setProdcutToOpen(prod))
            history.push(`/product/${id}`);
        }

    return (
        <div style={{minHeight:'77vh'}}>
            <Row>
                { 
                    searchedProducts.map((p, i)=>(
                        <div key={i} style={{position:'relative',height:'420px', width:'290px', marginLeft:'15px'}}>   
                        {Product(p, handleClick)}
                    </div>))
                }
            </Row>
        </div>
    )
}
