import { changeQuantity } from "./redux/ducks/cart_reducer"

export function changeItemQuantity(cartItem, dispatch, type){
    if (type === 'inc'){
        if (cartItem.quantity < cartItem.product.stock_unit){
            let q = cartItem.quantity + 1
            dispatch(changeQuantity(cartItem.product.id, q))
        } 
    } else {
        if (cartItem.quantity > 1){
            let q = cartItem.quantity - 1
            dispatch(changeQuantity(cartItem.product.id, q))
        }
    }
}

export function discountedPrice(product){
    return product.discount > 0? (product.price-(product.price *(product.discount/100))).toFixed(2):product.price
}

export function subTotalAmount(cartItems){
    let subtotal = 0
    cartItems.forEach(element => {
        subtotal += element.quantity * discountedPrice(element.product)
    });
    return subtotal
}

export function totalDeliveryFee(cartItems){
    let deliveryFee = 0
    cartItems.forEach(element => {
        deliveryFee += element.product.delivery_charges * element.quantity
    });
    return deliveryFee
}


