import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserType } from './redux/ducks/user_reducer'
import App from './App'

export default function AppSelector() {
    const [selectedUser, setSelectedUser] = useState(useSelector(state => state.user.userType))
    const dispatch = useDispatch()

    const handleUserSelection = (userType) => {
        dispatch(setUserType(userType))
        console.log('Type selected')
        setSelectedUser(userType)
    }

    return (
        <div>
                {
                    selectedUser !== undefined ? <App userType={selectedUser}/> :  userSelection()
                }
            
        </div>
    )

    function userSelection(){
        return (
            <div style={{textAlign:'center', paddingTop:'5%'}}>
                <img src="/assets/images/page_logo.png" style={{width:'120px', height:'120px'}}/>
                <br/>
                <br/>
                <div><button 
                onClick={()=>handleUserSelection('customer')}
                className="button" style={{width:'250px'}}>Customer</button></div>
                <div><button 
                onClick={()=>handleUserSelection('seller')}
                className="button" style={{width:'250px'}}>Seller</button></div>
            </div>
        )
    }
}
