import { takeLatest } from "@redux-saga/core/effects";
import {GET_PRODUCTS, GET_HOME_PRODUCTS, GET_SELLER_PRODUCTS} from "../ducks/products_reducer"
import { handleGetProducts, handleGetHomeProducts, handleSellerProducts } from "./handlers/products_hand";
import { handleGetBanners, handleGetCategories, handleSubcategoriesAgainstCategory } from "./handlers/categories_hand";
import {GET_BANNERS, GET_CATEGORIES, GET_SUB_CATEGORIES} from "../ducks/category_reducer"

export function* watcherSaga(){
    yield takeLatest(GET_PRODUCTS, handleGetProducts)
    yield takeLatest(GET_HOME_PRODUCTS, handleGetHomeProducts)
    yield takeLatest(GET_CATEGORIES, handleGetCategories)
    yield takeLatest(GET_SUB_CATEGORIES, handleSubcategoriesAgainstCategory)
    yield takeLatest(GET_BANNERS, handleGetBanners)
    yield takeLatest(GET_SELLER_PRODUCTS, handleSellerProducts)
}