import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { requestAllCategoriesSubcategories, requestDeleteImage, requestDeleteProduct, requestUpdateProduct, requestUploadProduct } from '../redux/sagas/requests/api_requests'
import Seller from '../helper/Seller'
import {TiDelete} from 'react-icons/ti'
import { CustomLoader } from '../components/loader'
import { selectdLocale } from '../langs/locale'
import { showPopupWindowProduct } from '../utils'
import { useHistory } from 'react-router'

export default function AddItem() {
    const [product, setProduct] = useState(useSelector(state => state.product.productToOpen))

    const {seller} = Seller()
    const history = useHistory()

    const lang = selectdLocale(useSelector(state => state.user.locale))

    // Preview Images 
    const [previewImages, setPreviewImages] = useState(undefined)
    const [pictures, setPictures] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [uploadStatus, setUploadStatus] = useState('')
    const [showPopup, setShowPopup] = useState(false)

    // Categories
    const [allCategories, setAllCategories] = useState([])
    const [categories, setCategories] = useState(undefined)
    const [subcategories, setSubcategories] = useState(undefined)

    const [values, setValues] = useState({
        title: '',
        selectedCategory: '',
        selectedSubcategory: '',
        description: '',
        price: '',
        stock: '',
        discount: '0',
        deliveryfee: '',
        prodCategoryId: '',
        prodCategory: undefined,
        prodSubcategory: undefined,
        prodImages: undefined
    })

    useEffect(() => {
        if (product !== undefined){
            const {product_name, price, category_id, delivery_charges, description, discount, stock_unit, images} = product
            setValues({...values, title: product_name, 
                price: price, prodCategoryId: category_id, stock: stock_unit, 
                discount: discount, description: description, deliveryfee: delivery_charges, prodImages: images})
        }
    }, [product])

    useEffect(() => {
        if (allCategories.length > 0){
            console.log('inside cats')
            const subcat = allCategories.filter((cat) => cat.id === values.prodCategoryId)
            if (subcat.length > 0){
                console.log(subcat)
                const parentCat = allCategories.filter((cat) => cat.id === subcat[0].parent_id)
                if (parentCat.length > 0){
                    console.log(parentCat[0])
                    setValues({...values, selectedCategory: parentCat[0].id, selectedSubcategory: subcat[0].id, prodCategory: parentCat[0], prodSubcategory: subcat[0]})
                }
                
            }
        }
    }, [allCategories])

    // Product Values
    const handleChange = (e) => {
        const {name, value} = e.target
        setValues(
            {
                ...values,
                [name]: value
            }
        )
    }

    // Upload Product
    const handleUpload = async () => {
        const mandatoryValues = pictures.length !== 0 && values.title !== '' && values.selectedCategory !== '' && values.selectedSubcategory !== '' && values.price !== '' && values.stock !== '' && values.deliveryfee !== ''

        if (mandatoryValues && seller !== undefined){
            setShowLoader(true)
            const response = await requestUploadProduct(seller.login_token, values.title, values.price, values.discount, values.selectedSubcategory, values.stock, values.description, seller.id, pictures, values.deliveryfee)
            setShowLoader(false)
            console.log(response.data)
            if (response.data.status === 'success'){
                clearValues()
                setUploadStatus(lang.uploadedSuccessfullyLabel)
                setShowPopup(true)
            } else {
                if (response.data.msg === 'user not fount'){
                    setUploadStatus('You need to login again to refresh login token!')
                    setShowPopup(true)
                } else {
                    setUploadStatus(lang.failedToUploadLabel)
                    setShowPopup(true)
                }
                
            }
            console.log(response.data.data)
        } else {
            setUploadStatus(lang.mandatoryFieldsLabel)
        }
    }

    // Clear Values
    function clearValues(){
        setPreviewImages(undefined)
        setPictures([])
        setValues({
            title: '',
            selectedCategory: '',
            selectedSubcategory: '',
            description: '',
            price: '',
            stock: '',
            discount: '0',
            deliveryfee: '',
            prodCategoryId: '',
            prodCategory: undefined,
            prodSubcategory: undefined,
            prodImages: undefined})
    }

    // Update Product
    const handleUpdate = async () => {
        if (seller !== undefined){
            setShowLoader(true)
            const response = await requestUpdateProduct(seller.login_token, values.title, values.price, values.discount, values.selectedSubcategory, values.stock, values.description, product.id, pictures, values.deliveryfee)
            setShowLoader(false)
            if (response.data.status === 'success'){
                setUploadStatus(lang.updatedSuccessfullyLabel)
                setShowPopup(true)
            } else {
                setUploadStatus(lang.failedToUpdateLabel)
                setShowPopup(true)
            }
            console.log(response.data)
        } else {
            console.log('seller not defined')
        }
    }

    // Delete Product
    const handleDelete = async () => {
        const r = window.confirm("Are you sure to delete the item?")
        if (r && seller !== undefined){
            setShowLoader(true)
            const response = await requestDeleteProduct(seller.login_token, product.id)
            setShowLoader(false)
            if (response.data.status === 'success'){
                setUploadStatus("Deleted Successfully")
                setShowPopup(true)
                history.push('/myitems')
            } else {
                setUploadStatus("Failed to delete!")
                setShowPopup(true)
            }
            console.log(response.data)
        } else {
            console.log('seller not defined')
        }
    }


    // Get All Categories Subcategories
    useEffect(async() => {
        const response = await requestAllCategoriesSubcategories()
        if (response.data.status === "success"){
            setAllCategories(response.data.data)
            let categories = []
            response.data.data.forEach(cat => {
                if (cat.parent_id === null){
                    categories.push(cat)
                }
            });
            setCategories(categories)
           
        }
    }, [])

    // Set Subcategories according Category
    useEffect(async () => {
        if (categories !== undefined && categories.length > 0 ){
            let subcats = []
            allCategories.forEach(cat => {
                if (cat.parent_id === values.selectedCategory){
                    subcats.push(cat)
                }
            })
            setSubcategories(subcats)
        }
    }, [values.selectedCategory])

    const w = window.innerWidth

    function readURI(e){
        if (e.target.files) {
    
            /* Get files in array form */
            const files = Array.from(e.target.files);
            setPictures(files)
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                /* Once all promises are resolved, update state with image URI array */
                setPreviewImages(images)
            }, error => {        
                console.error(error);
            });
        }
    }

    return (
        <div>
            {/* Loader  */}
            {showLoader ? <CustomLoader/>: ''}

            {/* Popup Box */}
            {showPopup ? showPopupWindowProduct(setShowPopup, uploadStatus, lang) : ''}

            <div style={{minHeight:'73vh', width: w > 500 ? '40%': '90%', margin:'auto', paddingBottom:'30px'}}>
                
                {/* Delete Product */}
                {product !== undefined ? deleteProduct(lang, handleDelete): ''}
                <br/>

                {/* Image Selection */}
                <div style={{textAlign:'left'}}>
                 <h5>{lang.selectImagesLabel}</h5>   
                <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Control 
                    onChange={(e)=>readURI(e)}
                    type="file" accept="image/*" multiple />
                </Form.Group>
                
                {previewImages !== undefined ? 
                    previewImages.map((image, index)=>(
                        <>
                            <img key={index} src={image} style={{width:'150px', height:'150px'}} /> 
                        </>
                    ))
                :''}
                </div>
                <br/>

                {/* Product Images */}
                {values.prodImages !== undefined ? 
                    productImages(values.prodImages)
                : ''}
                <br/>

                <h5 style={{textAlign:'left'}}>{lang.productTitleLabel}</h5>
                {/* Title Field */}
                <Form.Control name="title" value={values.title} onChange={handleChange} type="text" placeholder={lang.productTitleLabel}/>
                <br/>

                <h5 style={{textAlign:'left'}}>{lang.categoryLabel}</h5>
                {/* Category Selection */}
                <select 
                        value={values.selectedCategory}
                        name="selectedCategory"
                        style={{width:'100%', padding:'9px', marginTop:'7px', borderColor:'lightgray', borderRadius:'5px'}}
                        onChange={handleChange}>
                    <option value="">{lang.selectCategoryLabel}</option>
                    {
                        categories !== undefined ? categories.map(
                            (cat, ind) => (
                                <>
                                    <option key={ind} value={cat.id}>{cat.category_name}</option>
                                </>
                            )
                        ):''
                    }
                </select>
                <br/>
                <br/>

                <h5 style={{textAlign:'left'}}>{lang.subcategoryLabel}</h5>
                {/* Subcategory Selection */}
                <select 
                        value={values.selectedSubcategory}
                        name="selectedSubcategory"
                        style={{width:'100%', padding:'9px', marginTop:'7px', borderColor:'lightgray', borderRadius:'5px'}}
                        onChange={handleChange}>
                    <option value="">{lang.selectSubcategoryLabel}</option>
                    {
                        subcategories !== undefined ? subcategories.map(
                            (cat, ind) => (
                                <>
                                <option key={ind} value={cat.id}>{cat.category_name}</option>
                                </>
                            )
                        ):''
                    }
                </select>
                <br/>
                <br/>

                <h5 style={{textAlign:'left'}}>{lang.descriptionLabel}</h5>
                {/* Description Field */}
                <Form.Control value={values.description} name="description" onChange={handleChange} type="text" placeholder={lang.productDescriptionLabel}/>
                <br/>
                <br/>

                <h4 style={{textAlign:'left'}}>{lang.pricingStockLabel}</h4>
                <br/>
                <div style={{display:'flex'}}>
                    <h5 style={{textAlign:'left', padding:'5px'}}>{lang.priceLabel}:</h5>
                    <Form.Control value={values.price} name="price" onChange={handleChange} type="number" placeholder={lang.priceLabel+' $0'} style={{marginRight:'5px'}}/>
                    <h5 style={{textAlign:'left', padding:'5px'}}>{lang.stockLabel}:</h5>
                    <Form.Control value={values.stock} name="stock" onChange={handleChange} type="number" placeholder={lang.stockLabel+' 0'}/>
                </div>
                <br/>
                <div style={{display:'flex'}}>
                    <h5 style={{textAlign:'left', padding:'5px'}}>{lang.discountLabel}:</h5>
                    <Form.Control value={values.discount} name="discount" onChange={handleChange} type="number" placeholder={lang.discountLabel+' 0%'} style={{marginRight:'5px'}}/>
                    <h5 style={{textAlign:'left', padding:'5px'}}>{lang.deliveryLabel}:</h5>
                    <Form.Control value={values.deliveryfee} name="deliveryfee" onChange={handleChange} type="number" placeholder={lang.deliveryChargesLabel+' $0'}/>
                </div>
                <br/><br/>
                {product !== undefined? <button onClick={handleUpdate} className="button" style={{width:'200px'}}><span>{lang.updateProductLabel}</span></button>
                :<button onClick={handleUpload} className="button" style={{width:'200px'}}><span>{lang.uploadProductLabel}</span></button>}
                

            </div>
        </div>
    )

    function productImages(images){
        if (images.length > 1){
            return (
                <div style={{display:'flex'}}>
                    {images.map((image, ind)=>(
                            <div key={ind} style={{marginRight:'7px',textAlign:'left', position:'relative', width: '50px', height:'50px'}}>
                            <img 
                            style={{width:'50px', height:'50px'}}
                            src={`http://paolashopping.com/apis/storage/media/Products/${image.image_url}`} />
                            <div 
                            onClick={async ()=>{
                                setShowLoader(true)
                                const response = await requestDeleteImage(image.id)
                                setShowLoader(false)
                                if (response.data.status === 'success'){
                                    let imgs = values.prodImages.filter(img => img.id === image.id)
                                    setValues({...values, prodImages: imgs})
                                    setUploadStatus('Image deleted successfully')
                                    setShowPopup(true)
                                } else {
                                    setUploadStatus('Failed to delete image!')
                                    setShowPopup(true)
                                }
                            }}
                            style={{position:'absolute', right:'-8px', top:'-10px'}}><TiDelete style={{color:'red', fontSize:'25px'}}/></div>
                        </div>    
                    ))}        
                </div>
            )
        } 
        return (<></>)
    }
}



function deleteProduct(lang, handleDelete){
    return (
        <>
            <br/>
            <div>
                <button 
                    onClick={handleDelete}
                    className="button" style={{backgroundColor: 'red', width: '180px'}}>
                    <span>{lang.deletProductLabel}</span>
                </button>
            </div>
        </>
        
    )
}
