import { MdStars } from 'react-icons/md';
import { discountedPrice } from '../utils';

export function Product(p, handleClick){
   let totalRating = 0
   let count = 0
   p.reviews.forEach(element => {
    totalRating = totalRating + element.rating;
    count = count + 1
   });
    let rating = 0;
   if (count !== 0){
     rating = totalRating/count
   }
   
    return (
      <>
      <div 
      style={{marginTop:'5px', marginLeft:'1px', marginRight:'1px', minWidth:'290px'}}
      key={p.id}
      onClick={()=>handleClick(p.id, p)}
      className="ui link cards">
        <div className="card">
          <div className="image">
            <img style={{height:'250px'}} src={'http://paolashopping.com/apis/storage/media/Products/'+p.images[0].image_url}/>
          </div>
          <div className="content" style={{backgroundColor:'#eee'}}>
            <div className="header" style={{textAlign:'left'}}>{p.product_name}</div>
            <div claclassNamess="meta" style={{textAlign:'left'}}>
              <a>{p.sellerinfo[0].full_name}</a>
            </div>
          </div>
          <div claclassNamess="extra content" style={{textAlign:'left', backgroundColor:'#eee'}}>
          <div style={{display:'flex', marginBottom:'5px'}}>
                        <div style={{display:'flex'}}>
                          <div style={{textAlign:'left', paddingLeft:'12px', fontWeight:'bold'}}>${discountedPrice(p)}</div>
                          {p.discount > 0?
                            <div style={{fontSize:'12px',textAlign:'left', paddingLeft:'10px', paddingTop:'3px',color:'grey', textDecoration:'line-through'}}>${p.price}</div>
                          :''}
                          
                        </div>
                        <div style={{position:'absolute', right:'10px'}}>
                          <MdStars style={{color:'orange', fontSize:'18px'}}/>
                          <span style={{position:'relative', top:'2px',marginTop:'3px',marginLeft:'3px',color:'green', fontWeight:'bold'}}>{rating}</span>
                        </div>
                    </div>
          </div>
          {p.discount > 0 ?
             <div style={{borderRadius:'5px 0px 6px 0px',backgroundColor:'orangered',position:'absolute', top:'0px', left:'0px', width: '60px', height:'30px', color:'white', paddingTop:'3px'}}>-{p.discount}%</div>
          :''}

          {p.stock_unit === 0 ?
              <div style={{borderRadius:'0px 6px 0px 5px',backgroundColor:'orangered',position:'absolute', top:'0px', right:'0px', width: '130px', height:'30px', color:'white', paddingTop:'3px'}}>Out of stock</div>
          :''}
        </div>
        </div>
      </>
    )
    // return (
        // <div 
        //           onClick={()=>handleClick(p.id, p)}
        //           key={p.id} style={{ cursor:'pointer',height: 350, background: '#EEE', position:'relative', border:'1px solid #EEE', borderRadius:'10px 10px 0 0' }}>
                    // {p.discount > 0 ?
                    //  <div style={{borderRadius:'10px 0px 10px 0px',backgroundColor:'orangered',position:'absolute', top:'0px', left:'0px', width: '60px', height:'30px', color:'white', paddingTop:'3px'}}>-{p.discount}%</div>
                    // :''}

                    // {p.stock_unit === 0 ?
                    //   <div style={{borderRadius:'0px 10px 0px 10px',backgroundColor:'orangered',position:'absolute', top:'0px', right:'0px', width: '130px', height:'30px', color:'white', paddingTop:'3px'}}>Out of stock</div>
                    // :''}
                    
        //             <div style={{width:'100%', height:'75%', borderRadius:'10px 10px 0 0'}}>
        //             <img src={'http://paolashopping.com/apis/storage/media/Products/'+p.images[0].image_url} 
        //             style={{height:'100%', width:'100%', backgroundColor:'white', borderRadius:'10px 10px 0 0'}}/>
        //             </div>
        //             <div style={{textAlign:'left', paddingLeft: '10px', paddingTop:'3px'}}>{p.product_name}</div>
        //             <div style={{textAlign:'left', paddingLeft:'10px'}}><span style={{color:'grey', fontSize:'12px'}}>{p.sellerinfo[0].full_name}</span></div>
                    // <div style={{display:'flex'}}>
                    //     <div style={{display:'flex'}}>
                    //       <div style={{textAlign:'left', paddingLeft:'10px'}}>${p.discount > 0? (p.price-(p.price *(p.discount/100))).toFixed(2):p.price}</div>
                    //       {p.discount > 0?
                    //         <div style={{fontSize:'12px',textAlign:'left', paddingLeft:'10px', paddingTop:'3px',color:'grey', textDecoration:'line-through'}}>${p.price}</div>
                    //       :''}
                          
                    //     </div>
                    //     <div style={{position:'absolute', right:'10px'}}>
                    //       <MdStars style={{color:'orange', fontSize:'18px'}}/>
                    //       <span style={{position:'relative', top:'2px',marginTop:'3px',marginLeft:'3px',color:'green', fontWeight:'bold'}}>3.5</span>
                    //     </div>
                    // </div>
        //         </div>
    // )
}