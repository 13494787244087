import { changeQuantity } from "./redux/ducks/cart_reducer"
import {Col, Row} from 'react-bootstrap'
import { PRODUCT_HOST } from "./constants"

export function changeItemQuantity(cartItem, dispatch, type){
    if (type === 'inc'){
        if (cartItem.quantity < cartItem.product.stock_unit){
            let q = cartItem.quantity + 1
            dispatch(changeQuantity(cartItem.product.id, q))
        } 
    } else {
        if (cartItem.quantity > 1){
            let q = cartItem.quantity - 1
            dispatch(changeQuantity(cartItem.product.id, q))
        }
    }
}

export function discountedPrice(product){
    return product.discount > 0? (product.price-(product.price *(product.discount/100))).toFixed(2):product.price
}

export function subTotalAmount(cartItems){
    let subtotal = 0
    cartItems.forEach(element => {
        subtotal += element.quantity * discountedPrice(element.product)
    });
    return subtotal
}

export function totalDeliveryFee(cartItems){
    let deliveryFee = 0
    cartItems.forEach(element => {
        deliveryFee += element.product.delivery_charges * element.quantity
    });
    return deliveryFee
}

export function showPopupWindow(handleClose, uploadStatus, lang){
    return (
        <div className='popup' onClick={()=>handleClose('success')} style={{borderRadius:'10px'}}>
                <div className='popup_inner'>
                    <br/>
                    <br/>
                    <h4>{uploadStatus}</h4>
                    <br/>
                    <div><button className="button"><span>{lang.closeLabel}</span></button></div>
                </div>
            </div>
    )
}

export function showPopupWindowProduct(setShowPopup, uploadStatus, lang){
    return (
        <div className='popup' onClick={()=>setShowPopup(false)} style={{borderRadius:'10px'}}>
                <div className='popup_inner'>
                    <br/>
                    <br/>
                    <h4>{uploadStatus}</h4>
                    <br/>
                    <div><button className="button"><span>{lang.closeLabel}</span></button></div>
                </div>
            </div>
    )
}

export function productRow(product, quantity){
    return (
        <>
            <Row className="align-items-center" style={{padding:'5px'}}>
                <Col md="2"><img src={PRODUCT_HOST+product.images[0].image_url} style={{width:'80px', height:'80px'}}/></Col>
                <Col md="2"><div style={{fontWeight:'bold'}}>{product.product_name}</div></Col>
                <Col md="2"><div>Price: <span style={{color:'green'}}>${product.price}</span></div></Col>
               
                <Col md="2"><div>Delivery Fee: <span style={{color:'green'}}>${product.delivery_charges}</span></div></Col>
                <Col md="2"><div>Quantity: {quantity}</div></Col>
            </Row>
        </>
    )
}

export function paymentRow(totalPayment, status, lang){
    return (
        <div style={{backgroundColor:'#eee', display:'flex', borderRadius:'0px 0px 5px 5px'}}>
            <div style={{padding:'10px', textAlign:'left', width:'50%'}}>
                <div style={{fontWeight:'bold'}}>{lang.orderStatusLabel}</div>
                <div style={{fontWeight:'bold', color:'green'}}>{status}</div>
            </div>
            <div style={{padding:'10px', textAlign:'right', width:'50%'}}>
                <div style={{fontWeight:'bold'}}>{lang.paymentLabel}</div>
                <div style={{fontWeight:'bold', color:'green'}}>${totalPayment}</div>
            </div>
        </div>
        
    )
}


