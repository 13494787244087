import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'

function Seller() {
    const userData = useSelector(state => state.user.user)
    const [seller, setSeller] = useState(undefined)

    useEffect(() => {
        if (userData !== undefined){
            if (userData.user !== undefined){
                setSeller(userData.user)
            } else {
                setSeller(userData.data)
            }
        }
    }, [userData])

    return {seller}
}

export default Seller