import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const ADD_CART_ITEM = 'addcartitem'
const REMOVE_CART_ITEM = 'removecartitem'
const QNT_CHANGE = 'quantitychange'
const CLEAR_CART = 'clearcart'

const ADD_WISH_ITEM = 'addwishitem'
const REMOVE_WISH_ITEM = 'removewishitem'

export const addCartItem = (cartItem) => ({
    type: ADD_CART_ITEM,
    cartItem
})

export const changeQuantity = (id, qnt) => ({
    type: QNT_CHANGE,
    id,
    qnt
})

export const removeCartItem = (id) => ({
    type: REMOVE_CART_ITEM,
    id
})

export const clearCart = () => ({
    type: CLEAR_CART
})

export const addWishItem = (product) => ({
    type: ADD_WISH_ITEM,
    product
})

export const removeWishItem = (id) => ({
    type: REMOVE_WISH_ITEM,
    id
})

const initialState = {
    cartItems:[],
    wishItems: []
}

const cartItemsReducer = (state = initialState, action) => {
    switch(action.type){
        case ADD_CART_ITEM:
            const cartItem = action.cartItem;
            let isExists = false
            state.cartItems.forEach(element => {
                if (element.product.id === cartItem.product.id){
                    isExists = true
                }
            });
            if (!isExists){
                return {...state, cartItems:[
                    ...state.cartItems,
                    cartItem
                ]}
            } else {
                return {...state}
            }
            
        case QNT_CHANGE:
            return { 
                ...state, 
                cartItems: state.cartItems.map(
                    (cartItem, i) => cartItem.product.id === action.id ? {...cartItem, quantity: action.qnt}
                                            : cartItem
                )
             } 
        case REMOVE_CART_ITEM:
            const id = action.id;
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.product.id !== id)
            }
        case CLEAR_CART:
            return {...state, cartItems: []}    
        case ADD_WISH_ITEM:
            const product = action.product;
            const wishList = state.wishItems.filter(item => item.id === product.id)
            if (wishList.length === 0){
                return {
                    ...state,
                    wishItems: [
                        ...state.wishItems,
                        product
                    ]
                } 
            } else {
                return {...state}
            }
            
        case REMOVE_WISH_ITEM:
            return {
                ...state,
                wishItems: state.wishItems.filter(item => item.id !== action.id)
            }           
        default:
            return state;    
    }
}

const persistConfig = {
    keyPrefix: "panda-",
    key: "cartitems",
    storage
}

export default persistReducer( persistConfig, cartItemsReducer );