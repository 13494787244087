import React, {useState, useEffect} from 'react'
import Seller from '../helper/Seller'
import {GoLocation} from 'react-icons/go'
import {MdPhone} from 'react-icons/md'
import {BsPerson} from 'react-icons/bs'
import { selectdLocale } from '../langs/locale'
import { useSelector } from 'react-redux'
import { requestDeliveryBoysList, sendPickRequest } from '../redux/sagas/requests/api_requests'
import { CustomLoader, Loader } from '../components/loader'
import { showPopupWindow } from '../utils'
import { useHistory } from 'react-router'

export default function OrderPickRequest(props) {
    const [dBoys, setDBoys] = useState(undefined)
    // REQUEST STATUS
    const [showPopup, setShowPopup] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [requestStatus, setRequestStatus] = useState('')

    const orderId = props.match.params.id
    const history = useHistory()

    const {seller} = Seller()
    const lang = selectdLocale(useSelector(state => state.user.locale))

    useEffect(async () => {
        if (seller !== undefined){
            const response = await requestDeliveryBoysList(seller.id)
            if (response.data.status === 'success'){
                setDBoys(response.data.data)
            }
            console.log(response.data)
        }
    }, [seller])

    if (dBoys === undefined){
        return Loader()
    }

    // Send Request 
    const handleSendRequest = async (dboyId) => {
        setShowLoader(true)
        const response = await sendPickRequest(seller.id, orderId, dboyId)
                    setShowLoader(false)
                    console.log(response.data)
                    if (response.data.status === 'success'){
                        setRequestStatus(lang.requestSentSuccessLabel)
                        setShowPopup(true)
                    } else {
                        setRequestStatus(lang.failedToSendRequestLabel)
                        setShowPopup(true)
                    }
    }

    const handlePopupClose = (msg) => {
        if (msg === 'success'){
            history.goBack()
        }
    }

    const w = window.innerWidth
    return (
        <div className="main-content">
        {/* Loader  */}
        {showLoader ? <CustomLoader/>: ''}

        {/* Popup Box */}
        {showPopup ? showPopupWindow(handlePopupClose, requestStatus, lang) : ''}

        <div style={{minHeight:'73vh', width:w > 500 ? '50%': '90%', margin:'auto', paddingBottom:'10px' }}>
            {dBoys.length > 0 ? 
                dBoys.map((dboy, ind)=>(
                    <>
                        {deliveryBoyCard(dboy, ind)}
                    </>
                ))
            :'No delivery boy available'
            }
        </div>
        </div>
    )

    function deliveryBoyCard(dboy, ind){
        return (
            <div className="order-card" key={ind} style={{borderRadius:'5px', border:'1px solid #ccc', padding:'15px', marginBottom:'10px', width:'100%'}}>
                {/* Name of boy */}
                <div style={{textAlign:'left'}}>
                    <div style={{fontSize:'20px', fontWeight:'bold'}}><BsPerson style={{fontSize:'25px', paddingRight:'5px', paddingBottom:'2px'}}/>{dboy.full_name}</div>
                </div>
                <hr/>
                {/* Phone Number Distance */}
                <div style={{display:'flex', marginTop:'5px'}}>
                    <div style={{width:'50%', textAlign:'left'}}><MdPhone style={{fontSize:'22px',paddingRight:'5px'}}/> {dboy.mobile_number}</div>
                    <div style={{width:'50%', textAlign:'right'}}>{lang.distanceLabel}: {dboy.distance.toFixed(2)} km</div>
                </div>
    
                {/* Address of Boy */}
                <div style={{textAlign:'left', marginTop:'5px'}}>
                    <span><GoLocation style={{paddingRight:'5px', fontSize:'16px'}}/></span>
                    <span>{dboy.address}.</span>
                </div>
    
                {/* Pick Request Button */}
                <br/>
                <button 
                onClick={()=> handleSendRequest(dboy.id)}
                className="button" style={{width: '220px'}}>
                    <span>{lang.requestToPickOrderLabel}</span>
                </button>
            </div>
        )
    }
}


